import { preloadEditorData } from '@confluence/load-edit-page/entry-points/preloadEditorData';
import { preloadEditorOnboardingState } from '@confluence/onboarding-helpers/entry-points/preloadEditorOnboardingState';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';
import { preloadDraft } from '@confluence/fabric-providers/entry-points/preloadDraft';
import { preloadBreadcrumbs } from '@confluence/breadcrumbs/entry-points/preloadBreadcrumbs';
import { preloadContentTypesHeader } from '@confluence/content-types-header/entry-points/preloadContentTypesHeader';
import { preloadClassificationContextForEditContent } from '@confluence/data-classification/entry-points/queries';
import { preloadMediaToken } from '@confluence/fabric-media-support/entry-points/preloadMediaToken';
import { preloadSuggestedTemplateQuery } from '@confluence/blank-page-quick-actions/entry-points/preloadSuggestedTemplateQuery';
import { preloadPageRestrictionsQuery } from '@confluence/page-restrictions-context/entry-points/preloadPageRestrictionsQuery';
import { preloadRequestViewQuery } from '@confluence/no-permission/entry-points/preloadRequestViewQuery';
import { preloadShareAndRestrictButtonQuery } from '@confluence/share-and-restrict-dialog/entry-points/preloadShareAndRestrictButtonQuery';
import { fg } from '@confluence/feature-gating';
import { ATL_GENERAL } from '@confluence/web-item-location/entry-points/WEB_FRAGMENT_LOCATIONS';
import { preloadWebPanelLocation } from '@confluence/web-panel-location/entry-points/preloadWebPanelLocation';
import { preloadRecordLoomButton } from '@confluence/loom-utils/entry-points/preloadRecordLoomButton';
import { preloadContentSmartLinks } from '@confluence/content-smartlinks/entry-points/preloadContentSmartLinks';
import { AccessStatus } from '@confluence/session-data';

import { getIsEmbeddedConfluence } from './matchHelpers';
import { matchEditBlog } from './matchRoutes';
import { preloadNavigationTasks } from './preloadNavigationTasks';

export const preloadEditPageRoute = async (
	match: RouteMatch,
	_url: string,
	isTransition: boolean = false,
) => {
	const { userId, isLicensed, cloudId, accessStatus, isLoggedIn, environment } =
		await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { contentId, spaceKey } = match.params;

	const isBlog = Boolean(matchEditBlog(_url));

	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	const shouldPreloadMentionsProvider = Boolean(process.env.REACT_SSR);
	const isEmbeddedConfluence = getIsEmbeddedConfluence(match);
	const shouldPreloadNavigation = !isEmbeddedConfluence;

	tasks.push(
		preloadBreadcrumbs(spaceKey, contentId, false),
		preloadEditorData({
			spaceKey,
			userId,
			contentId,
			cloudId,
			shouldPreloadMentionsProvider,
			useCache: false,
			isBlog,
		}),
	);

	if (fg('platform_ssr_smartlinks_editor')) {
		tasks.push(preloadContentSmartLinks(contentId));
	}

	if (fg('confluence_frontend_object_header')) {
		tasks.push(preloadContentTypesHeader(contentId, fg('confluence_frontend_object_header')));
		// Standalone Restrictions button needs to have its data preloaded
		tasks.push(preloadPageRestrictionsQuery(contentId, false));
	}

	// Unified Share and Restrict Button
	if (fg('confluence_frontend_usd_ssr')) {
		tasks.push(preloadShareAndRestrictButtonQuery(contentId));
	}

	if (spaceKey && fg('confluence_preload_blank_page_quick_actions')) {
		tasks.push(preloadSuggestedTemplateQuery({ spaceKey }));
	}

	if (isLicensed) {
		tasks.push(preloadEditorOnboardingState());
	}

	if (isTransition) {
		return Promise.all(tasks);
	}

	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		// Navigation and page tree
		if (shouldPreloadNavigation) {
			tasks.push(preloadNavigationTasks(spaceKey, contentId, isLicensed, isBlog));
		}

		tasks.push(
			preloadMediaToken(contentId),
			preloadClassificationContextForEditContent(contentId, spaceKey),
		);
	} else {
		tasks.push(preloadDraft(contentId));
	}

	tasks.push(preloadSpaceDetail(spaceKey));

	if (fg('confluence_preload_webpanel_atl_general')) {
		tasks.push(
			preloadWebPanelLocation({
				contentId,
				location: ATL_GENERAL,
			}),
		);
	}

	if (fg('cc_ssr_edit_page_restricted')) {
		tasks.push(preloadRequestViewQuery(contentId));
	}

	// Preload Loom button in Action Bar
	if (
		(accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
			accessStatus === AccessStatus.LICENSED_USE_ACCESS) &&
		isLoggedIn
	) {
		tasks.push(
			preloadRecordLoomButton({
				cloudId,
				accessStatus,
				isLoggedIn,
				userId,
				environment,
			}),
		);
	}

	return Promise.all(tasks);
};
