import gql from 'graphql-tag';

export const LivePageUnifiedQuery = gql`
	query LivePageUnifiedQuery(
		$userId: ID
		$contentId: ID!
		$spaceKey: String!
		$isUsdSsr: Boolean!
		$shouldIncludePermissionType: Boolean!
	) {
		content(id: $contentId, status: ["current", "draft"]) {
			nodes {
				id
				status
				type
				properties(key: "content-appearance-draft") {
					nodes {
						id
						key
						value
						version {
							number
						}
					}
				}
				metadata {
					frontend {
						coverPictureWidth
					}
					currentuser {
						favourited {
							isFavourite
						}
					}
				}
				contentState {
					id
					name
					color
					restrictionLevel
					isCallerPermitted
				}
				contentStateLastUpdated(format: USER_FRIENDLY) {
					value
				}
				operations {
					operation
					targetType
				}
			}
		}
		space(key: $spaceKey) {
			id
			settings {
				customHeaderAndFooter {
					header {
						html
						js
						css
						spaUnfriendlyMacros {
							name
						}
					}
					footer {
						html
						js
						css
						spaUnfriendlyMacros {
							name
						}
					}
				}
			}
		}
		availableContentStates(contentId: $contentId) {
			customContentStates {
				name
				color
				id
			}
			spaceContentStates {
				name
				color
				id
				restrictionLevel
			}
		}
		publicLinkPage(pageId: $contentId) @include(if: $isUsdSsr) {
			pageId
			pageStatus
		}
		user(id: $userId) {
			id
			displayName
			photos {
				value
				isPrimary
			}
			confluence @include(if: $shouldIncludePermissionType) {
				permissionType
			}
		}
		nativeCollabQuery: content(id: $contentId, status: ["draft", "current"]) {
			nodes {
				id
				type
				status
				subType
				version {
					number
					ncsStepVersion
					by {
						type
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
				}
				labels {
					nodes {
						id
						name
					}
				}
			}
		}
	}
`;
