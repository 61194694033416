export class TestSupport {
	public static readonly DISABLE_METADATA_CALLS_PROPERTY = '__awc_disable_metadata_calls__';
	public static readonly DISABLE_EVENT_CALLS_PROPERTY = '__awc_disable_event_calls__';
	public static readonly DISABLE_FETCH_CALLS_PROPERTY = '__awc_disable_fetch_calls__';

	public static isGeminiVREnvironment(): boolean {
		try {
			for (const k in global) {
				if (k.startsWith('__gemini_') && k.endsWith('__')) {
					return true;
				}
			}
		} catch (e) {
			// ignore
		}
		return false;
	}

	public static areMetadataCallsDisabled(): boolean {
		return (
			this.areFetchCallsDisabled() ||
			this.getGlobalBooleanProperty(TestSupport.DISABLE_METADATA_CALLS_PROPERTY)
		);
	}

	public static areEventCallsDisabled(): boolean {
		return (
			this.areFetchCallsDisabled() ||
			this.getGlobalBooleanProperty(TestSupport.DISABLE_EVENT_CALLS_PROPERTY)
		);
	}

	public static areFetchCallsDisabled(): boolean {
		return this.getGlobalBooleanProperty(TestSupport.DISABLE_FETCH_CALLS_PROPERTY);
	}

	private static getGlobalBooleanProperty(propertyName: string): boolean {
		if (global && global.hasOwnProperty(propertyName)) {
			return (global as any)[propertyName] === true;
		}
		return false;
	}
}
