import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { adaptEventHandler } from '../../utils';
import {
	default as PlatformComment,
	CommentAuthor,
	CommentEdited,
	CommentTime,
	CommentAction,
} from '@atlaskit/comment';

// Public API - hand-picked subset of the following props:
export type CommentProps = Omit<
	React.ComponentProps<typeof PlatformComment>,
	'time' | 'author' | 'edited' | 'actions' | 'errorActions'
> & {
	time?: {
		onClick?: React.ComponentProps<typeof CommentTime>['onClick'];
		text: string;
	};
	author?: {
		onClick?: React.ComponentProps<typeof CommentAuthor>['onClick'];
		text: string;
	};
	edited?: string;
	actions?: Array<{
		onClick?: React.ComponentProps<typeof CommentAction>['onClick'];
		text: string;
	}>;
	errorActions?: Array<{
		onClick?: React.ComponentProps<typeof CommentAction>['onClick'];
		text: string;
	}>;
};

// Omit for overriding props that are React components
export type InternalCommentProps = Omit<CommentProps, 'content' | 'avatar'> & {
	children: React.ReactNode;
};

export const Comment = (props: Parameters<RenderFn>[0]) => {
	const {
		actions,
		afterContent,
		author,
		edited,
		errorActions,
		errorIconLabel,
		headingLevel,
		highlighted,
		id,
		isError,
		isSaving,
		restrictedTo,
		savingText,
		shouldRenderNestedCommentsInline,
		testId,
		time,
		type,
	} = props.forgeDoc.props as InternalCommentProps;

	const avatarForgeDoc = props.forgeDoc.children.find((child) => {
		if (child.type === 'ContentWrapper' && child.props?.name === 'avatar') {
			return child;
		}
	});

	const contentForgeDoc = props.forgeDoc.children.find((child) => {
		if (child.type === 'ContentWrapper' && child.props?.name === 'content') {
			return child;
		}
	});

	return (
		<PlatformComment
			actions={actions?.map((action, index) => {
				return (
					<CommentAction key={index} onClick={adaptEventHandler(action?.onClick)}>
						{action.text}
					</CommentAction>
				);
			})}
			afterContent={afterContent}
			author={
				author ? (
					<CommentAuthor onClick={adaptEventHandler(author?.onClick)}>{author.text}</CommentAuthor>
				) : undefined
			}
			avatar={avatarForgeDoc?.children.map(props.render)}
			children={props.forgeDoc.children
				.filter((child) => child.type !== 'ContentWrapper')
				.map((c) => (
					<React.Fragment key={c.key}>{props.render(c)}</React.Fragment>
				))}
			content={contentForgeDoc?.children.map(props.render)}
			edited={edited ? <CommentEdited>{edited}</CommentEdited> : undefined}
			errorActions={errorActions?.map((action, index) => {
				return (
					<CommentAction key={index} onClick={adaptEventHandler(action?.onClick)}>
						{action.text}
					</CommentAction>
				);
			})}
			errorIconLabel={errorIconLabel}
			headingLevel={headingLevel}
			highlighted={highlighted}
			id={id}
			isError={isError}
			isSaving={isSaving}
			restrictedTo={restrictedTo}
			savingText={savingText}
			shouldRenderNestedCommentsInline={shouldRenderNestedCommentsInline}
			testId={testId}
			time={
				time ? (
					<CommentTime onClick={adaptEventHandler(time?.onClick)}>{time.text}</CommentTime>
				) : undefined
			}
			type={type}
		/>
	);
};
