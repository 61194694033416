import memoize from 'memoize-one';

import { type UserInfo } from '../types';

import { FetchError } from './types';

export type MetadataParams = {
	product: string;
	userInfo?: UserInfo | null;
	tenantInfo?: any | null;
};

export const fetchMetadata = async (
	url: string,
	params: MetadataParams,
	timeout?: number,
	signal?: AbortSignal | null,
): Promise<Response> => {
	const requestAbortController = createRequestAbortController(timeout, signal);

	const corsOptions = getCorsForUrl(url);

	const metadataUrl =
		url +
		'?' +
		new URLSearchParams({
			product: `${params.product}`,
			userId: `${params.userInfo?.userId || ''}`,
			userIdType: `${params.userInfo?.userIdType || ''}`,
			tenantId: `${params.tenantInfo?.tenantId || ''}`,
			tenantIdType: `${params.tenantInfo?.tenantIdType || ''}`,
		}).toString();

	const response = await fetch(metadataUrl, {
		method: 'GET',
		signal: requestAbortController.signal,
		...corsOptions,
	});

	// Response must be a 200 (we expect a JSON response, so 204 is not valid for instance)
	if (response.status !== 200) {
		throw new FetchError(
			`Metadata: HTTP Error ${response.status} (${response.statusText})`,
			response.status,
			response.statusText,
		);
	}

	return response;
};

const createRequestAbortController = (
	timeout?: number,
	signal?: AbortSignal | null,
): AbortController => {
	const abortController = new AbortController();
	setTimeout(() => {
		abortController.abort();
	}, timeout || 30000);
	signal?.addEventListener('abort', () => {
		abortController.abort();
	});
	return abortController;
};

// To ensure we always have cookies, we should check if the origins match.
// If they dont, we need to set mode to `cors` and credentials to `include`.
const getCorsForUrl = memoize((url: string): RequestInit => {
	const origin = new URL(url).origin;
	if (origin === location.origin) {
		return {};
	} else {
		return {
			mode: 'cors',
			credentials: 'include',
		};
	}
});
