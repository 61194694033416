import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Inline, xcss } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlaskit/platform-feature-flags';
import { IconButton } from '@atlaskit/button/new';
import InformationIcon from '@atlaskit/icon/utility/information';
import Tooltip from '@atlaskit/tooltip';

const i18n = defineMessages({
	archivedSpacesTooltip: {
		id: 'bulk-permissions.space.archived-spaces-tooltip',
		defaultMessage: 'Archived spaces are not included in bulk permissions actions.',
		description: 'Tooltip text for the space selection section for the bulk access page',
	},
	archivedSpacesTooltipIconLabel: {
		id: 'bulk-permissions.space.archived-spaces-tooltip-icon-label',
		defaultMessage: 'Archived space information',
		description: 'Label for the archived spaces tooltip icon',
	},
	spaceSelectionHeader: {
		id: 'bulk-permissions.space.space-selection-header',
		defaultMessage: 'Choose which spaces',
		description: 'Header text of the space selection section',
	},
	spaceAccessSelectionHeader: {
		id: 'bulk-permissions.space.space-access-selection-header',
		defaultMessage: 'On which spaces<asterisk>*</asterisk>',
		description: 'Header text of the space selection section for the bulk access page',
	},
	spaceSelectionOne: {
		id: 'bulk-permissions.space.space-selection-one',
		defaultMessage: 'All (excluding personal)',
		description: 'Option to select all Confluence spaces except personal spaces',
	},
	spaceSelectionTwo: {
		id: 'bulk-permissions.space.space-selection-two',
		defaultMessage: 'All',
		description: 'Option to select all Confluence spaces including personal spaces',
	},
	spaceSelectionThree: {
		id: 'bulk-permissions.space.space-selection-three',
		defaultMessage: 'Only personal',
		description: 'Option to select personal Confluence spaces',
	},
});

export enum SpaceSelection {
	AllExcludingPersonal = 'all-excluding-personal',
	AllIncludingPersonal = 'all-including-personal',
	OnlyPersonal = 'only-personal',
}

const spaceSelectionHeaderStyle = xcss({
	font: token('font.body.large'),
	fontWeight: token('font.weight.medium'),
	display: 'flex',
	flexDirection: 'row',
	paddingTop: 'space.300',
	paddingBottom: 'space.100',
});

const asteriskStyle = xcss({
	color: 'color.text.accent.red',
});

type SpaceSelectionRadioButtonProps = {
	spaceSelection: SpaceSelection;
	onRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SpaceSelectionRadioButtons = ({
	spaceSelection,
	onRadioChange,
}: SpaceSelectionRadioButtonProps) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Inline xcss={spaceSelectionHeaderStyle} space="space.050" alignBlock="center">
				<Box>
					{formatMessage(
						fg('cc_perms_exp_rbac_fe_bulk_access')
							? i18n.spaceAccessSelectionHeader
							: i18n.spaceSelectionHeader,
						{
							asterisk: (chunks: React.ReactNode[]) => <Box xcss={asteriskStyle}>{chunks}</Box>,
						},
					)}
				</Box>
				<Tooltip content={formatMessage(i18n.archivedSpacesTooltip)} position="right">
					{(tooltipProps) => (
						<IconButton
							icon={InformationIcon}
							appearance="subtle"
							label={formatMessage(i18n.archivedSpacesTooltipIconLabel)}
							spacing="compact"
							shape="circle"
							{...tooltipProps}
						/>
					)}
				</Tooltip>
			</Inline>
			<Box>
				<Radio
					value={SpaceSelection.AllExcludingPersonal}
					onChange={onRadioChange}
					isChecked={spaceSelection === SpaceSelection.AllExcludingPersonal}
					name="space-selection"
					label={formatMessage(i18n.spaceSelectionOne)}
				/>
				<Radio
					value={SpaceSelection.AllIncludingPersonal}
					onChange={onRadioChange}
					isChecked={spaceSelection === SpaceSelection.AllIncludingPersonal}
					name="space-selection"
					label={formatMessage(i18n.spaceSelectionTwo)}
				/>
				<Radio
					value={SpaceSelection.OnlyPersonal}
					onChange={onRadioChange}
					isChecked={spaceSelection === SpaceSelection.OnlyPersonal}
					name="space-selection"
					label={formatMessage(i18n.spaceSelectionThree)}
				/>
			</Box>
		</>
	);
};
