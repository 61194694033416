import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useIntl, defineMessages } from 'react-intl-next';

import { BulkSetRoleAssignmentToSpacesMutation } from './graphql/BulkSetRoleAssignmentToSpacesMutation.graphql';
import type {
	BulkSetRoleAssignmentToSpaces_bulkSetRoleAssignmentToSpaces as BulkSetRoleAssignmentToSpacesResponse,
	BulkSetRoleAssignmentToSpacesVariables,
} from './graphql/__types__/BulkSetRoleAssignmentToSpacesMutation';
import { BulkRemoveRoleAssignmentFromSpacesMutation } from './graphql/BulkRemoveRoleAssignmentFromSpacesMutation.graphql';
import type {
	BulkRemoveRoleAssignmentFromSpaces_bulkRemoveRoleAssignmentFromSpaces as BulkRemoveRoleAssignmentFromSpacesResponse,
	BulkRemoveRoleAssignmentFromSpacesVariables,
} from './graphql/__types__/BulkRemoveRoleAssignmentFromSpacesMutation';

const i18nBulk = defineMessages({
	asyncSuccessFlagTitle: {
		id: 'bulk-permissions.async.update.success.non-final',
		defaultMessage: 'Bulk space roles updated',
		description: 'Title of the success flag for bulk space roles',
	},
	asyncSuccessFlagDescription: {
		id: 'bulk-permissions.async.update.success.description.non-final',
		defaultMessage: 'The bulk space roles have been updated successfully',
		description: 'Description of the success flag for bulk space roles',
	},
	errorFlagTitle: {
		id: 'bulk-permissions.error.flag.title',
		defaultMessage: 'Bulk space roles update failed',
		description: 'Title of the error flag for bulk space roles',
	},
	errorFlagDescription: {
		id: 'bulk-permissions.error.flag.description',
		defaultMessage: 'The bulk space roles update failed',
		description: 'Description of the error flag for bulk space roles',
	},
});

export const useBulkRoleSetAssignment = ({ flags, closeDialog }) => {
	const { formatMessage } = useIntl();

	const onMutationErrored = useCallback(() => {
		closeDialog();
		void flags.showErrorFlag({
			id: 'bulk-space-roles.add.error',
			title: formatMessage(i18nBulk.errorFlagTitle),
			description: formatMessage(i18nBulk.errorFlagDescription),
			isAutoDismiss: true,
		});
	}, [closeDialog, flags, formatMessage]);

	const onAsyncMutationSubmitted = useCallback(
		(data) => {
			closeDialog();
			/**
			 * The backend is counting the mutation as a success for some exceptions
			 * but returning an error in the mutation response.  We need to check for
			 * these here.
			 */
			if (
				data?.bulkSetSpacePermissionAsync?.errors?.length > 0 ||
				data?.bulkRemoveRoleAssignmentFromSpaces?.errors?.length > 0
			) {
				onMutationErrored();
			} else {
				void flags.showSuccessFlag({
					id: 'bulk-space-permissions.success',
					title: 'Bulk permissions change processing',
					description: 'These changes can take a while. We’ll notify you when it’s finished.',
					isAutoDismiss: true,
				});
			}
		},
		[closeDialog, flags, onMutationErrored],
	);

	const [bulkSetRoleAssignmentToSpaces, { data: bulkAssignmentData }] = useMutation<
		BulkSetRoleAssignmentToSpacesResponse,
		BulkSetRoleAssignmentToSpacesVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
		BulkSetRoleAssignmentToSpacesMutation,
		{
			onCompleted: onAsyncMutationSubmitted,
			onError: () => {
				closeDialog();
				onMutationErrored();
			},
		},
	);

	const [bulkRemoveRoleAssignment, { data: bulkRemoveAssignmentData }] = useMutation<
		BulkRemoveRoleAssignmentFromSpacesResponse,
		BulkRemoveRoleAssignmentFromSpacesVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
		BulkRemoveRoleAssignmentFromSpacesMutation,
		{
			onCompleted: onAsyncMutationSubmitted,
			onError: () => {
				closeDialog();
				onMutationErrored();
			},
		},
	);

	return {
		bulkSetRoleAssignmentToSpaces,
		bulkRemoveRoleAssignment,
		bulkAssignmentData,
		bulkRemoveAssignmentData,
	};
};
