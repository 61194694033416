import { preloadPostOfficePlacementWithSessionContext } from '@atlassian/confluence-post-office-provider/entry-points/preload-placement';

import { expValEqualsNoExposure } from '@confluence/feature-experiments';
import { SSRMeasures } from '@confluence/action-measures';
import type { PreloadFnContext } from '@confluence/query-preloader-tools';

export const preloadPostOfficeConfluenceSideNav = async (
	preloadedSessionContext: PreloadFnContext,
) => {
	const tasks: Promise<any>[] = [];

	const isLocaleEligible = preloadedSessionContext.locale.toLowerCase().startsWith('en');

	if (
		preloadedSessionContext.userId &&
		isLocaleEligible &&
		// Suppress exposure as this is not the true exposure point, check required to prevent unnecessary preloading
		// eslint-disable-next-line confluence-feature-gating/no-exposure-recommendation
		expValEqualsNoExposure('post-office_expt_confluence-next-best-actions', 'cohort', 'variation')
	) {
		const preloadPostOfficeSideNav = async () => {
			return SSRMeasures.run(
				'ssr-app/prepare/preloadQuery/postOffice:ConfluenceSideNav',
				async () => {
					return preloadPostOfficePlacementWithSessionContext(
						'confluence-side-nav',
						preloadedSessionContext,
					);
				},
			);
		};

		tasks.push(preloadPostOfficeSideNav());
	}

	return Promise.all(tasks);
};
