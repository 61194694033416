import { type NetworkStatusChangeCallback, NetworkStatusEnum } from './types';

export default class NetworkStatus {
	private _networkChangeCallback: NetworkStatusChangeCallback;

	constructor(networkChangeCallback: NetworkStatusChangeCallback) {
		this._networkChangeCallback = networkChangeCallback;
		if (typeof window !== 'undefined') {
			window.addEventListener('offline', () =>
				this._networkChangeCallback(NetworkStatusEnum.OFFLINE),
			);
			window.addEventListener('online', () =>
				this._networkChangeCallback(NetworkStatusEnum.ONLINE),
			);
		}
	}

	public removeListeners() {
		if (typeof window !== 'undefined') {
			window.addEventListener('offline', () =>
				this._networkChangeCallback(NetworkStatusEnum.OFFLINE),
			);
			window.addEventListener('online', () =>
				this._networkChangeCallback(NetworkStatusEnum.ONLINE),
			);
		}
	}

	getNetworkStatus(): NetworkStatusEnum {
		if (window.navigator.onLine) {
			return NetworkStatusEnum.ONLINE;
		}
		return NetworkStatusEnum.OFFLINE;
	}
}
