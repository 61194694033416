import { useContext, useEffect } from 'react';
import type { VFC } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { getLogger } from '@confluence/logger';

const logger = getLogger('notifications');

export const NotificationLandedEvent: VFC = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { getQueryParams, setQueryParams } = useContext(RoutesContext);
	const isLivePage = useIsCurrentPageLive();

	// Check for the notification landed query params "atl_p" when loading
	useEffect(() => {
		const queryParams = getQueryParams();
		const base64NotificationParams = queryParams.atl_p as string;

		// If we have the query param for the event, send it and clear them
		if (base64NotificationParams) {
			// Its a Base64 encoded JSON string which we need to unpack
			try {
				const paramsJSONStr = window.atob(base64NotificationParams);
				const params = JSON.parse(paramsJSONStr);

				// Ensure we have a "source" field in the param
				if (params && !params.source) {
					throw new Error('source field is required to send event');
				}

				const attributes = Object.assign({ isLivePage }, params);
				delete attributes['source'];

				// COMMENTS-372 Persisting originId for cc-notifications to use
				// @ts-ignore
				window.atlp = { originId: params.i };

				// Send event
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'landed',
						actionSubject: 'notification',
						source: params.source,
						attributes,
					},
				}).fire();
			} catch (e) {
				logger.error`An error occurred attempting to decode the notifications query params: ${e}`;
			}

			// Unset the query params
			setQueryParams({
				...queryParams,
				atl_p: undefined,
			});
		}
	}, [getQueryParams, createAnalyticsEvent, setQueryParams, isLivePage]);

	return null;
};
