import type { Scopes } from '@atlassian/post-office-context';

export const scopes: Scopes = [
	{
		name: 'all',
		when: {
			route: {
				pathname: {
					matches: '.*',
				},
			},
		},
	},
	{
		name: 'admin/all',
		when: {
			context: {
				product: 'admin',
			},
		},
	},
	{
		name: 'admin/products',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'products',
				},
			},
		},
	},
	{
		name: 'admin/discovered-products',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'discovered-products',
				},
			},
		},
	},
	{
		name: 'admin/domains',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'domains',
				},
			},
		},
	},
	{
		name: 'admin/user-access-settings',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'user-access-settings',
				},
			},
		},
	},
	{
		name: 'admin/authentication-policies',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'authentication-policies',
				},
			},
		},
	},
	{
		name: 'admin/users',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'users',
				},
			},
		},
	},
	{
		name: 'admin/audit-log',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'audit-log',
				},
			},
		},
	},
	{
		name: 'admin/billing-preview',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'billing-preview',
				},
			},
		},
	},
	{
		name: 'admin/data-security-policies',
		when: {
			context: { product: 'admin' },
			route: {
				pathname: {
					matches: 'data-security-policies',
				},
			},
		},
	},

	{
		name: 'jira/all',
		when: {
			context: { product: 'jira' },
		},
	},
	{
		name: 'jira/kanban',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'board',
				},
			},
		},
	},
	{
		name: 'confluence/all',
		when: {
			context: { product: 'confluence' },
		},
	},
	{
		name: 'pop3/all',
		when: {
			context: { product: 'pop3' },
		},
	},
	{
		name: 'pop3/send-it',
		when: {
			context: { product: 'pop3' },
			route: {
				pathname: {
					matches: 'send-it',
				},
			},
		},
	},
	{
		name: 'trello/all',
		when: {
			context: {
				product: 'trello',
			},
		},
	},
	{
		name: 'jira/queue',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'queue',
				},
			},
		},
	},
	{
		name: 'jira/browse',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'browse',
				},
			},
		},
	},
	{
		name: 'jira/issues',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'issues',
				},
			},
		},
	},
	{
		name: 'jira/software',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'jira/software',
				},
			},
		},
	},
	{
		name: 'jira/polaris',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'jira/polaris',
				},
			},
		},
	},
	{
		name: 'jira/backlog',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'backlog',
				},
			},
		},
	},
	{
		name: 'jira/servicedesk',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'jira/servicedesk',
				},
			},
		},
	},
	{
		name: 'jira/project',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: '.*(?=.*project)(?=.*setting).*',
				},
			},
		},
	},
	{
		name: 'jira/your-work',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'your-work',
				},
			},
		},
	},
	{
		// Handles both CMP Software and Business
		name: 'jira/cmp/project-settings/people',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'plugins/servlet/project-config/.*?/people',
				},
			},
		},
	},
	{
		name: 'jira/software/tmp/project-settings/access',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'jira/software/projects/.*?/settings/access',
				},
			},
		},
	},
	{
		name: 'jira/business/tmp/project-settings/access',
		when: {
			context: { product: 'jira' },
			route: {
				pathname: {
					matches: 'jira/core/projects/.*?/settings/access',
				},
			},
		},
	},
];
