import { preloadPostOfficeConfluenceSideNav } from '@confluence/experiment-post-office-side-nav/entry-points/preloadConfluenceSideNav';
import { fg } from '@confluence/feature-gating';
import { getPreloaderFnContext, query } from '@confluence/query-preloader-tools';
import { preloadUseExtensionList } from '@confluence/forge-ui/entry-points/preloadUseExtensionList';
import { FORGE_MODULE_SPACE_PAGE } from '@confluence/forge-ui/entry-points/ForgeModuleType';
import { preloadWebItemLocation } from '@confluence/web-item-location/entry-points/preloadWebItemLocation';
import { getIsNav4Enabled } from '@confluence/nav4-enabled';

import { SpaceNavigationQuery } from './SpaceNavigation/SpaceNavigationQuery.graphql';

export async function preloadSideNavigationNeedsContentId(spaceKey: string, contentId: string) {
	return preloadUseExtensionList({
		contentId,
		spaceKey,
		moduleType: FORGE_MODULE_SPACE_PAGE,
	});
}

export async function preloadSideNavigation(spaceKey: string) {
	const preloadedSessionContext = await getPreloaderFnContext();
	const isNav4Enabled = getIsNav4Enabled();

	const tasks: Array<Promise<any>> = [
		query({
			query: SpaceNavigationQuery,
			variables: {
				spaceKey,
				isLicensed: preloadedSessionContext.isLicensed,
			},
		}),
	];

	if (isNav4Enabled) {
		tasks.push(
			preloadWebItemLocation({
				location: 'system.header/left',
			}),
		);
	}

	if (fg('po-connie-side-nav-ssr-shadow')) {
		tasks.push(preloadPostOfficeConfluenceSideNav(preloadedSessionContext));
	}

	return Promise.all(tasks);
}
