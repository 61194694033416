import NetworkStatus from '../resilienceQueue/NetworkStatus';
import { DEFAULT_POLLING_OPTIONS } from '../resilienceQueue/PullBatchableQueue';
import Scheduler, { DoneState, type OnDoneFn } from '../resilienceQueue/scheduler';
import {
	NetworkStatusEnum,
	type RetryQueueOptions,
	type StrictRetryQueueOptions,
} from '../resilienceQueue/types';
import { isStargateProxyPath } from '../selectHost';
import { type TenantInfo, type UserInfo } from '../types';
import { TestSupport } from '../util/testSupport';

import {
	DEFAULT_METADATA_PROPERTY_CHANGE_REFRESH_INTERVAL,
	DEFAULT_METADATA_REFRESH_INTERVAL,
	DEFAULT_METADATA_REQUEST_TIMEOUT,
} from './defaults';
import { fetchMetadata } from './fetchMetadata';
import {
	ALLOW_LISTED_FETCH_ERROR_NAMES,
	FetchError,
	MetadataClientMetrics,
	MetadataResponse as MetadataInfo,
} from './types';

export interface IMetadataClient {
	start(allowRestart: boolean): void;
	stop(): void;
	getMetadataAsync(): Promise<MetadataInfo | null>;
	get status(): string;
	get lastErrorReason(): string | null;
	get lastFetchRequestCount(): number;
	get userInfo(): UserInfo | null;
	set userInfo(userInfo: UserInfo | null);
	get tenantInfo(): TenantInfo | null;
	set tenantInfo(tenantInfo: TenantInfo | null);
	get metrics(): MetadataClientMetrics;
}

export type MetadataClientFactory = (
	apiHostProtocol: string,
	apiHost: string,
	product: string,
) => IMetadataClient;

export class MetadataClient implements IMetadataClient {
	private _userInfo: UserInfo | null;
	private _tenantInfo: any | null;
	private _url: string;
	private _metadataHost: string;
	private _product: string;
	private _metadataInfo: MetadataInfo | null;
	private _requestStatus: string;
	private _lastFetchRequestCount: number;
	private _options: StrictRetryQueueOptions;
	private _scheduler: Scheduler;
	private _networkStatus: NetworkStatus | null;
	private _lastErrorReason: string | null;
	private _fetchAbortController: AbortController | null;
	private _hasBeenStopped: boolean;
	private _isEnabled: boolean;
	private _propertyChangeRefreshTimeout: NodeJS.Timeout | null;

	public static readonly INITIAL_REFRESH_DELAY = 1;

	public static Factory: MetadataClientFactory = (
		apiHostProtocol: string,
		apiHost: string,
		product: string,
	) => {
		return new MetadataClient(apiHostProtocol, apiHost, product);
	};

	constructor(apiHostProtocol: string, apiHost: string, product: string) {
		this._userInfo = { anonymousId: '' };
		this._tenantInfo = {};
		this._url = `${apiHostProtocol}://${apiHost}/metadata`;
		this._product = product;
		this._metadataInfo = null;
		this._requestStatus = 'PENDING';
		this._lastFetchRequestCount = 0;
		this._lastErrorReason = null;
		this._fetchAbortController = null;
		this._hasBeenStopped = false;
		this._isEnabled = false;
		this._propertyChangeRefreshTimeout = null;

		const isStargateProxy = isStargateProxyPath({ apiHost: apiHost });
		this._metadataHost = isStargateProxy ? 'PRODUCT_HOST' : apiHost;

		this._options = this.buildOptions({
			backoffFactor: 2,
			backoffJitterPercentage: 0.2,
			batchFlushSize: 7,
			flushBeforeUnload: false,
			flushWaitMs: 500,
			maxAttempts: 10,
			maxItems: 1000,
			maxRetryDelay: 30000,
			minRetryDelay: 1000,
		});

		this._scheduler = new Scheduler(
			{
				...this._options,
				waitInterval: DEFAULT_METADATA_REFRESH_INTERVAL,
			},
			this.scheduleCallback.bind(this),
		);
		this._networkStatus = null;
	}

	/**
	 * Starts the metadata client. This will start the scheduler and begin
	 * fetching metadata.
	 *
	 * @param allowRestart - If false, the client will not be restarted if it has already been stopped
	 *						 by a call to stop().
	 */
	public start(allowRestart: boolean): void {
		if (this._isEnabled || (this._hasBeenStopped && !allowRestart)) {
			return;
		}
		this._isEnabled = true;
		this.startNetworkStatusMonitor();

		setTimeout(() => {
			this._scheduler.schedule({ immediate: true });
		}, MetadataClient.INITIAL_REFRESH_DELAY);
	}

	public stop(): void {
		if (!this._isEnabled) {
			return;
		}
		this._hasBeenStopped = true;
		this._isEnabled = false;
		this.stopNetworkStatusMonitor();
		this._scheduler.stop();
		this._fetchAbortController?.abort();
		this.resetPropertyChangeRefresh();
	}

	private startNetworkStatusMonitor(): void {
		if (this._networkStatus) {
			return;
		}
		this._networkStatus = new NetworkStatus((status) => {
			if (status === NetworkStatusEnum.OFFLINE) {
				this._scheduler.stop();
			} else if (this._isEnabled) {
				this._scheduler.schedule({ immediate: this._metadataInfo === null });
			}
		});
	}

	private stopNetworkStatusMonitor(): void {
		if (this._networkStatus) {
			this._networkStatus.removeListeners();
			this._networkStatus = null;
		}
	}

	public async refreshMetadataAsync(): Promise<void> {
		if (TestSupport.areFetchCallsDisabled() || !this._isEnabled) {
			return Promise.resolve();
		}

		this._fetchAbortController?.abort();
		this._fetchAbortController = new AbortController();
		const fetchAbortSignal = this._fetchAbortController.signal;

		this._lastFetchRequestCount = this._scheduler.getFailureCount();

		await fetchMetadata(
			this._url,
			{
				product: this._product,
				userInfo: this._userInfo,
				tenantInfo: this._tenantInfo,
			},
			DEFAULT_METADATA_REQUEST_TIMEOUT,
			fetchAbortSignal,
		)
			.then(async (response) => {
				const metadata = await response.json();
				this._metadataInfo = MetadataInfo.fromJson(metadata);
				this._lastFetchRequestCount++;
				this._requestStatus = 'FETCHED';
				this._lastErrorReason = null;
			})
			.catch((error: any) => {
				if (fetchAbortSignal.aborted) {
					// This request was aborted, so we don't need to handle it.
					return;
				}

				this._requestStatus = 'FAILED';
				if (error instanceof FetchError && [429, 503].includes(error.statusCode)) {
					this._lastErrorReason = 'ServerBusyError';
				} else {
					this._lastErrorReason =
						error instanceof Error && ALLOW_LISTED_FETCH_ERROR_NAMES.includes(error.name)
							? error.name
							: 'Unknown';
				}
				throw error;
			});
	}

	public getMetadataAsync(): Promise<MetadataInfo | null> {
		return Promise.resolve(this._metadataInfo);
	}

	public get status(): string {
		return this._requestStatus;
	}

	public get lastErrorReason(): string | null {
		return this._lastErrorReason;
	}

	public get lastFetchRequestCount(): number {
		return this._lastFetchRequestCount;
	}

	public get userInfo(): UserInfo | null {
		return this._userInfo;
	}

	public set userInfo(userInfo: UserInfo | null) {
		if (
			this._userInfo?.userId === userInfo?.userId &&
			this._userInfo?.userIdType === userInfo?.userIdType
		) {
			return;
		}
		this._userInfo = userInfo;
		this.schedulePropertyChangeRefresh();
	}

	public get tenantInfo(): TenantInfo | null {
		return this._tenantInfo;
	}

	public set tenantInfo(tenantInfo: TenantInfo | null) {
		if (
			this._tenantInfo?.tenantId === tenantInfo?.tenantId &&
			this._tenantInfo?.tenantIdType === tenantInfo?.tenantIdType
		) {
			return;
		}

		this._tenantInfo = tenantInfo;
		this.schedulePropertyChangeRefresh();
	}

	public get metrics(): MetadataClientMetrics {
		return {
			host: this._metadataHost,
			status: this.status,
			lastErrorReason: this.lastErrorReason,
			lastRefreshRequestCount: this.lastFetchRequestCount,
		};
	}

	private async scheduleCallback(done: OnDoneFn): Promise<void> {
		if (this._networkStatus?.getNetworkStatus() === NetworkStatusEnum.OFFLINE) {
			done(DoneState.NOOP);
			return;
		}

		try {
			await this.refreshMetadataAsync();
			done(DoneState.SUCCESS);
			this._scheduler.schedule();
		} catch (error: any) {
			done(DoneState.ERROR);
		}
	}

	/**
	 * Schedules a refresh of the metadata when the user or tenant info changes. There is
	 * a short delay to allow for multiple changes to be batched together, such as when the
	 * callers sets the user info and tenant info in quick succession.
	 */
	private schedulePropertyChangeRefresh(): void {
		if (!this._isEnabled) {
			return;
		}
		this.resetPropertyChangeRefresh();
		this._propertyChangeRefreshTimeout = setTimeout(() => {
			this.resetPropertyChangeRefresh();
			this._scheduler.schedule({ immediate: true });
		}, DEFAULT_METADATA_PROPERTY_CHANGE_REFRESH_INTERVAL);
	}

	private resetPropertyChangeRefresh(): void {
		if (this._propertyChangeRefreshTimeout) {
			clearTimeout(this._propertyChangeRefreshTimeout);
			this._propertyChangeRefreshTimeout = null;
		}
	}

	private buildOptions(options?: RetryQueueOptions): StrictRetryQueueOptions {
		return {
			backoffFactor: options?.backoffFactor || DEFAULT_POLLING_OPTIONS.backoffFactor,
			backoffJitterPercentage:
				options?.backoffJitterPercentage !== undefined
					? options.backoffJitterPercentage
					: DEFAULT_POLLING_OPTIONS.backoffJitterPercentage,
			batchFlushSize: options?.batchFlushSize || DEFAULT_POLLING_OPTIONS.batchFlushSize,
			flushBeforeUnload: options?.flushBeforeUnload || DEFAULT_POLLING_OPTIONS.flushBeforeUnload,
			flushWaitMs: options?.flushWaitMs || DEFAULT_POLLING_OPTIONS.flushWaitMs,
			maxItems: options?.maxItems || DEFAULT_POLLING_OPTIONS.maxItems,
			maxAttempts: options?.maxAttempts || DEFAULT_POLLING_OPTIONS.maxAttempts,
			maxRetryDelay: options?.maxRetryDelay || DEFAULT_POLLING_OPTIONS.maxRetryDelay,
			minRetryDelay: options?.minRetryDelay || DEFAULT_POLLING_OPTIONS.minRetryDelay,
		};
	}
}
