import uuid from 'uuid/v4';

import {
	aliasType,
	envType,
	eventType,
	groupType,
	isType,
	objectValues,
	originType,
	perimeterType,
	platformType,
	tenantType,
	type userType,
} from './analyticsWebTypes';
import ApdexEvent from './apdexEvent';
import createGetter from './createGetter';
import { isFirstParty, isThirdParty } from './embedContext';
import {
	buildActionEvent,
	buildActionName,
	buildContext,
	buildScreenEvent,
	type validationMode,
} from './eventBuilder';
import EventDelayQueue, { type StopLowPriorityEventDelayReason } from './eventDelay';
import {
	validateContainers,
	validateIdentifyEvent,
	validateOperationalEvent,
	validatePlatform,
	validateScreenEvent,
	validateTrackEvent,
	validateUIEvent,
} from './eventValidation';
import EventProcessor from './integration';
import { type AnalyticsResponse, type ResponseCallback } from './integration/eventProcessor';
import { MetadataClient } from './integration/metadataClient';
import OriginTracing from './originTracing';
import PageVisibility from './pageVisibility';
import { type Logger } from './resiliencedb';
import { type RetryQueueOptions } from './resilienceQueue';
import { selectHost } from './selectHost';
import SessionTracking from './sessionTracking';
import SafeSessionStorage from './storage/SafeSessionStorage';
import TabTracking from './tabTracking';
import TaskSessionStore from './taskSessionStore';
import TestingCache from './testingCache';
import {
	type Context,
	type InternalProductInfoType,
	type OperationalEventPayload,
	type ProductEmbeddedContext,
	type ProductInfoType,
	type SendScreenEventInput,
	type SettingsType,
	type TenantInfo,
	type TrackEventPayload,
	type UIEventPayload,
	type UserInfo,
} from './types';
import UIViewedEvent from './uiViewedEvent';
import { defaultHistoryReplaceFn } from './urlUtils';
import { TestSupport } from './util/testSupport';
import wrapCallback from './wrapCallback';
import { XIDPromise } from './xid';

export const STARGATE_PROXY_PATH = '/gateway/api/gasv3/api/v1';
const LAST_SCREEN_EVENT_STORAGE_KEY = 'last.screen.event';

export class LoggingResponseCallback {
	static getCallback(logger: Logger | null) {
		logger = logger || console;
		return (response: AnalyticsResponse) => {
			LoggingResponseCallback.callback(response, logger);
		};
	}

	private static callback(response: AnalyticsResponse, logger: Logger) {
		const statusCode = response.getStatusCode();
		if (statusCode >= 200 && statusCode <= 299) {
			response.getEventResponseList().forEach((eventResponse) => {
				logger.warn(
					'Validation report for event with message ID %s:\n',
					eventResponse.getMessageId(),
					eventResponse,
				);
			});
		} else if (statusCode === 400 || statusCode === 404) {
			logger.warn(
				'GASv3 submission failed with HTTP Status Code %d: %s',
				response.getStatusCode(),
				response.getMessage(),
			);
			response.getEventResponseList().forEach((eventResponse) => {
				logger.warn('Event with message ID %s:\n', eventResponse.getMessageId(), eventResponse);
			});
		} else {
			logger.log(
				'GASv3 submission failed with HTTP Status Code %d: %s',
				response.getStatusCode(),
				response.getMessage(),
			);
			response.eventResponseList.forEach((eventResponse) => {
				logger.debug('Event with message ID %s:\n', eventResponse.getMessageId(), eventResponse);
			});
		}
	}
}

export default class AnalyticsWebClient {
	private _apdexEvent: ApdexEvent;
	private _context: Context;
	private _eventDelayQueue: EventDelayQueue;
	private _historyReplaceFn: any;
	private _orgInfo: any;
	private _pageVisibility: any;
	private _internalProductInfo: InternalProductInfoType;
	private _safeSessionStorage: SafeSessionStorage;
	private _sessionTracking: SessionTracking;
	private _tabTracking: TabTracking;
	private _tenantInfo: TenantInfo;
	private _testingCache: TestingCache;
	private _uiViewedAttributes: any;
	private _uiViewedEvent?: UIViewedEvent | null;
	private _userInfo: UserInfo;
	private _originTracing: OriginTracing;
	private _task: TaskSessionStore;
	private _pageLoadId: string;
	private _workspaceInfo: any;
	private _aliases: any;
	private _groups: any;
	private _eventProcessor: EventProcessor;
	private _logger: Logger;
	private _embeddedContext?: ProductEmbeddedContext;

	constructor(productInfo: ProductInfoType, settings: SettingsType = {}) {
		if (!productInfo) {
			throw new Error('Missing productInfo');
		}

		if (!productInfo.env) {
			throw new Error('Missing productInfo.env');
		}

		if (!productInfo.product) {
			throw new Error('Missing productInfo.product');
		}

		if (!isType(envType, productInfo.env)) {
			throw new Error(
				`Invalid productInfo.env '${productInfo.env}', ` +
					`must be an envType: [${objectValues(envType)}]`,
			);
		}

		if (productInfo.perimeter && !isType(perimeterType, productInfo.perimeter)) {
			throw new Error(
				`Invalid productInfo.perimeter '${productInfo.perimeter}', ` +
					`must be an perimeterType: [${objectValues(perimeterType)}]`,
			);
		}

		if (!productInfo.origin) {
			productInfo.origin = originType.WEB;
		} else if (!isType(originType, productInfo.origin)) {
			throw new Error(
				`Invalid productInfo.origin '${productInfo.origin}', ` +
					`must be an originType: [${objectValues(originType)}]`,
			);
		}

		if (!productInfo.platform) {
			productInfo.platform =
				productInfo.origin === originType.WEB ? platformType.WEB : platformType.DESKTOP;
		} else {
			validatePlatform(productInfo);
		}

		this._logger = settings.logger || console;

		this._internalProductInfo = {
			...productInfo,
			subproduct: this._createSubproductGetter(productInfo.subproduct),
			embeddedProduct: this._createEmbeddedProductGetter(productInfo.embeddedProduct),
		};
		this._tenantInfo = {};
		this._orgInfo = {};
		this._uiViewedAttributes = {};
		if (productInfo?.embeddedContext) {
			this._embeddedContext = productInfo.embeddedContext;

			if (isFirstParty(this._embeddedContext)) {
				this.setUIViewedAttributes({
					embeddedType: this._embeddedContext['embeddedType'],
					embeddedFromProduct: this._embeddedContext['embeddedFromProduct'],
					embeddedFromSubproduct: this._embeddedContext['embeddedFromSubproduct'],
					embeddedFromTopLevelDomain: this._embeddedContext['embeddedFromTopLevelDomain'],
				});
			}

			if (isThirdParty(this._embeddedContext)) {
				this.setUIViewedAttributes({
					embeddedType: this._embeddedContext['embeddedType'],
					embeddedFromTopLevelDomain: this._embeddedContext['embeddedFromTopLevelDomain'],
				});
			}
		}

		this._context = buildContext(this._internalProductInfo);
		this._safeSessionStorage = new SafeSessionStorage();

		const useStargate = this._useStargate(settings.useStargate);
		const apiHost =
			settings.apiHost ||
			selectHost({
				useStargate,
				env: productInfo.env,
				useLegacyUrl: settings.useLegacyUrl,
				perimeter: productInfo.perimeter,
				envOverride: productInfo.envOverride,
			});
		const apiHostProtocol = settings.apiHostProtocol || 'https';

		const minRetryDelay = settings.minRetryDelay || 1000;
		const maxAttempts =
			settings.maxRetryAttempts !== undefined &&
			Number.isInteger(settings.maxRetryAttempts) &&
			settings.maxRetryAttempts >= 0
				? settings.maxRetryAttempts + 1
				: undefined;
		const retryQueueOptions: RetryQueueOptions = {
			maxRetryDelay: 60000,
			minRetryDelay,
			backoffFactor: 2,
			flushWaitMs: settings.flushWaitInterval,
			flushBeforeUnload: settings.flushBeforeUnload,
			maxAttempts: maxAttempts,
		};

		const retryQueuePrefix = `awc-${productInfo.env}`;

		const xidPromiseGetter = () => XIDPromise(settings.xidConsent, settings.xidPromiseFn);

		const disableCookiePersistence = settings.disableCookiePersistence || false;

		const metadataClientFactory = settings.metadataClientFactory || MetadataClient.Factory;
		const metadataClient = metadataClientFactory(apiHostProtocol, apiHost, productInfo.product);

		this._eventProcessor = new EventProcessor({
			apiHost,
			apiHostProtocol,
			product: productInfo.product,
			retryQueuePrefix,
			retryQueueOptions,
			xidPromiseGetter,
			logger: this._logger,
			metadataClient: metadataClient,
			disableCookiePersistence,
			responseCallback: LoggingResponseCallback.getCallback(this._logger),
			env: productInfo.env,
			perimeter: productInfo.perimeter,
			enableMetadataCalls: this.metadataCallsEnabled(settings),
			enableEventCalls: this.eventCallsEnabled(settings),
		});

		this._userInfo = {
			anonymousId: this._eventProcessor
				.getUser()
				.getAnonymousId(settings?.customAnonymousIdGenerator),
		};

		this._pageVisibility = new PageVisibility();
		this._tabTracking = new TabTracking();
		this._sessionTracking = new SessionTracking({
			sessionExpiryTime: settings.sessionExpiryTime,
			onNewSessionStarted: settings.onNewSessionStarted,
		});

		this._task = new TaskSessionStore();
		this._originTracing = new OriginTracing();

		// Init Apdex
		this._apdexEvent = new ApdexEvent(this.sendOperationalEvent, this._pageVisibility);

		this._historyReplaceFn =
			typeof settings.historyReplaceFn === 'function'
				? settings.historyReplaceFn
				: defaultHistoryReplaceFn;

		this._eventDelayQueue = new EventDelayQueue(
			this._fireDelayedEvent,
			settings.delayQueueCompressors || [],
		);
		this._testingCache = new TestingCache();

		this._pageLoadId = uuid();

		this._workspaceInfo = {};
		this._aliases = {};
		this._groups = {};
	}

	private metadataCallsEnabled(settings: SettingsType): boolean {
		if (typeof settings.disableMetadataCalls === 'boolean') {
			return !settings.disableMetadataCalls;
		}
		return !TestSupport.isGeminiVREnvironment() && !TestSupport.areFetchCallsDisabled();
	}

	private eventCallsEnabled(settings: SettingsType): boolean {
		if (typeof settings.disableEventCalls === 'boolean') {
			return !settings.disableEventCalls;
		}
		return !TestSupport.isGeminiVREnvironment() && !TestSupport.areFetchCallsDisabled();
	}

	private _useStargate = (useStargate?: boolean): boolean => {
		if (useStargate == null) {
			return true;
		}
		return useStargate;
	};

	private _changeInternalUserId = (userId: string | undefined, anonymousId?: string) => {
		this._eventProcessor.getUser().setUserId(userId);

		if (anonymousId && anonymousId !== this._eventProcessor.getUser().getAnonymousId()) {
			// Setting anonymous id can take a long time. Reading is a lot faster.
			// Only update if it has changed.
			this._eventProcessor.getUser().setAnonymousId(anonymousId);
		}
	};

	private _createSubproductGetter = (subproduct: any) =>
		createGetter(subproduct, 'Cannot get subproduct from the callback. Proceeding without it.');

	private _createEmbeddedProductGetter = (embeddedProduct: any) =>
		createGetter(
			embeddedProduct,
			'Cannot get embeddedProduct from the callback. Proceeding without it.',
		);

	private _getLastScreenEvent = () => {
		try {
			return JSON.parse(this._safeSessionStorage.getItem(LAST_SCREEN_EVENT_STORAGE_KEY) || '');
		} catch (err) {
			this._safeSessionStorage.removeItem(LAST_SCREEN_EVENT_STORAGE_KEY);
			return null;
		}
	};

	private _setLastScreenEvent = (event: any) => {
		this._safeSessionStorage.setItem(
			LAST_SCREEN_EVENT_STORAGE_KEY,
			JSON.stringify({
				name: event.name,
				attributes: event.attributes,
			}),
		);
	};

	private _shouldEventBeDelayed = (event: any) => {
		// TODO: this is a temporary restriction for the purposes of the Track All Changes project
		// The delay mechanism has a chance of event loss, which we can only accept for our own data at this point.
		// Once the delay queue implementation has been improved and measured to confirm that it is reliable enough,
		// then we will be able to open it up for other products to use by removing this check.
		if (!event.tags || event.tags.indexOf('measurement') === -1) {
			return false;
		}

		const isEventHighPriority = event.highPriority !== false; // defaults to true if excluded
		return this._eventDelayQueue.isDelayingLowPriorityEvents() && !isEventHighPriority;
	};

	private _fireEvent = (
		identifier: string,
		builtEvent: any,
		context: Context,
		callback: any,
	): Promise<void> => {
		switch (builtEvent.eventType) {
			case eventType.UI:
			case eventType.OPERATIONAL:
			case eventType.TRACK:
				return this._eventProcessor.track(identifier, builtEvent, context, callback);
			case eventType.SCREEN:
				return this._eventProcessor.page(identifier, builtEvent, context, callback);
			case eventType.IDENTIFY:
				return this._eventProcessor.identify(identifier, builtEvent, context, callback);
			default:
				throw new Error(`No handler has been defined for events of type ${builtEvent.eventType}`);
		}
	};

	private _fireDelayedEvent = (
		identifier: any,
		builtEvent: any,
		context: Context,
		userInfo: UserInfo,
	) => {
		try {
			// User information can change while the delay period is active, so we need to restore the values that
			// were active when the event was originally fired.
			this._changeInternalUserId(userInfo.userId, userInfo.anonymousId);
			builtEvent.tags = [...(builtEvent.tags || []), 'sentWithDelay'];

			// The callbacks for delayed events are fired immediately, so there is nothing to pass through for this argument.
			this._fireEvent(identifier, builtEvent, context, undefined);
		} finally {
			this._changeInternalUserId(this._userInfo.userId, this._userInfo.anonymousId);
		}
	};

	private _delayEvent = (
		identifier: string,
		builtEvent: any,
		context: Context,
		userInfo: UserInfo,
		callback: any,
	) => {
		this._eventDelayQueue.push(identifier, builtEvent, context, userInfo);
		// Fire the callback immediately, as we can consider the event successfully processed at this point
		if (callback) {
			callback();
		}
	};

	private _processEvent = (
		identifier: string,
		builtEvent: any,
		context: Context,
		callback: any,
	): Promise<void> => {
		this._testingCache.saveEvent(builtEvent);
		if (this._shouldEventBeDelayed(builtEvent)) {
			this._delayEvent(identifier, builtEvent, context, this._userInfo, callback);
			return Promise.resolve();
		} else {
			return this._fireEvent(identifier, builtEvent, context, callback);
		}
	};

	public setEmbeddedProduct = (embeddedProduct: any) => {
		this._internalProductInfo.embeddedProduct = this._createEmbeddedProductGetter(embeddedProduct);
		this.resetUIViewedTimers();
	};

	public clearEmbeddedProduct = () => {
		this._internalProductInfo.embeddedProduct = this._createEmbeddedProductGetter(null);
	};

	public setSubproduct = (subproduct: any) => {
		this._internalProductInfo.subproduct = this._createSubproductGetter(subproduct);
		this.resetUIViewedTimers();
	};

	/**
   * Calling this function in the intialisation of the client in product
   * captures specified 'origin tracing' URL params and fires a single origin landed event
   * <p>
   * This function expects a mapping between the keys for any URL parameters
   *  that should be captured and removed for origin tracing
   * Multiple parameters may be captured simultaneously if multiple key: handler function pairs are provided
   * Each handler function should return an object with two items
   * a) 'originTracingAttributes' - an object that will be added to the 'origin landed' event's attributes under 'originTracing
   * b) 'taskSessionId' (optional) - an Id string that will be added to the tasksessions for any event that fires from the tab, with the key
   *    matching the URL parameter, for the purpose of attributing subsequent analytics event to the origin land.
   * </p>
   * The general use case for this feature is for allowing attributation of user behaviour to a out of product or cross product link,
   * e.g. from a share or email
   *
   * An example calling this function using an external decoding library, with taskSessionId specified to persist
   * analyticsWebClient.setOriginTracingHandlers({
        atlOrigin: encodedOrigin => {
            const { id, product } = OriginTracing.fromEncoded(encodedOrigin);
            return { originTracingAttributes: {'id': id, 'product': product}, taskSessionId: id };
        },
    });
   *
   * @param  {Object} originParamHandlerMapping a dictionary of mappings between origin url param keys and handler functions
   * @this {AnalyticsWebClient}
   */
	public setOriginTracingHandlers = (originParamHandlerMapping: any): Promise<void> => {
		const capturedOriginTraces = this._originTracing.handleOriginParameters(
			originParamHandlerMapping,
			this._historyReplaceFn,
		);
		Object.keys(capturedOriginTraces).forEach((x) => {
			if (typeof capturedOriginTraces[x].taskSessionId !== 'undefined') {
				this._task.createTaskSessionWithProvidedId(x, capturedOriginTraces[x].taskSessionId);
			}
		});
		const originAttributes: any = {};
		Object.keys(capturedOriginTraces).forEach((x) => {
			if (capturedOriginTraces[x].originTracingAttributes) {
				originAttributes[x] = capturedOriginTraces[x].originTracingAttributes;
			} else {
				// eslint-disable-next-line no-console
				console.warn(`Handling method for origin parameter ${x} has not returned any attributes`);
			}
		});
		if (Object.keys(capturedOriginTraces).length > 0) {
			return this.sendOperationalEvent(
				{
					action: 'landed',
					actionSubject: 'origin',
					source: 'webClient',
					attributes: { originTracesLanded: originAttributes },
				},
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				() => {},
			);
		}
		return Promise.resolve();
	};

	public setTenantInfo = (tenantIdType: tenantType, tenantId?: string) => {
		if (!tenantIdType) {
			throw new Error('Missing tenantIdType');
		}

		if (tenantIdType !== tenantType.NONE && !tenantId) {
			throw new Error('Missing tenantId');
		}

		if (!isType(tenantType, tenantIdType)) {
			throw new Error(
				`Invalid tenantIdType '${tenantIdType}', ` +
					`must be an tenantType: [${objectValues(tenantType)}]`,
			);
		}

		this._tenantInfo = {
			tenantIdType,
			tenantId,
		};
		this._eventProcessor.refreshMetadata(this._userInfo, this._tenantInfo);
	};

	public clearTenantInfo = () => {
		this._tenantInfo = {};
		this._eventProcessor.refreshMetadata(this._userInfo, this._tenantInfo);
	};

	public setOrgInfo = (orgId: any) => {
		if (!orgId) {
			throw new Error('Missing orgId');
		}
		this._orgInfo = {
			orgId,
		};
	};

	public clearOrgInfo = () => {
		this._orgInfo = {};
	};

	public setWorkspaceInfo = (workspaceId: any) => {
		if (!workspaceId) {
			throw new Error('Missing workspaceId');
		}
		this._workspaceInfo = {
			workspaceId,
		};
	};

	public clearWorkspaceInfo = () => {
		this._workspaceInfo = {};
	};

	public setUserInfo = (userIdType: string, userId: string) => {
		validateIdentifyEvent(userIdType, userId);
		this._changeInternalUserId(userId);
		this._userInfo = {
			userIdType: userIdType as userType,
			userId,
			anonymousId: this._eventProcessor.getUser().getAnonymousId(),
		};
		this._eventProcessor.refreshMetadata(this._userInfo, this._tenantInfo);
	};

	public clearUserInfo = () => {
		this._changeInternalUserId(undefined);
		this._userInfo = {
			anonymousId: this._eventProcessor.getUser().getAnonymousId(),
		};
		this._eventProcessor.refreshMetadata(this._userInfo, this._tenantInfo);
	};

	public setAlias = (aliasKeyType: aliasType, alias: string) => {
		if (!aliasKeyType) {
			throw new Error('Missing aliasType');
		}
		if (!isType(aliasType, aliasKeyType)) {
			throw new Error(
				`Invalid aliasType '${aliasKeyType}', ` +
					`must be an aliasType: [${objectValues(aliasType)}]`,
			);
		}
		this._aliases[aliasKeyType] = alias;
	};

	public clearAlias = () => {
		this._aliases = {};
	};

	public setGroup = (groupsKeyType: groupType, group: string) => {
		if (!groupsKeyType) {
			throw new Error('Missing groupType');
		}
		if (!isType(groupType, groupsKeyType)) {
			throw new Error(
				`Invalid groupType '${groupsKeyType}', ` +
					`must be an groupType: [${objectValues(groupType)}]`,
			);
		}
		this._groups[groupsKeyType] = group;
	};

	public clearGroup = () => {
		this._groups = {};
	};

	public getAnonymousId = () => this._userInfo.anonymousId;

	public setUIViewedAttributes = (uiViewedAttributes: any) => {
		if (!uiViewedAttributes) {
			throw new Error('Missing uiViewedAttributes');
		}
		if (typeof uiViewedAttributes !== 'object' || Array.isArray(uiViewedAttributes)) {
			throw new Error('Invalid uiViewedAttributes type, should be a non array object');
		}
		this._uiViewedAttributes = { ...uiViewedAttributes };
	};

	public getUIViewedAttributes = () => {
		return this._uiViewedAttributes;
	};

	public clearUIViewedAttributes = () => {
		this._uiViewedAttributes = {};
	};

	public sendIdentifyEvent = (
		userIdType: string,
		userId: string,
		callback?: any,
	): Promise<void> => {
		this.setUserInfo(userIdType, userId);
		const builtEvent = {
			userIdType,
			eventType: eventType.IDENTIFY,
		};

		return this._processEvent(userId, builtEvent, this._context, callback);
	};

	/**
	 * @deprecated
	 * please use {@link sendScreenEvent instead)
	 */
	public sendPageEvent = (name: string, callback: any): Promise<void> => {
		return this.sendScreenEvent(name, callback);
	};

	/**
	 * send screen event
	 * @param event The event / For retrocompatibility event name is still supported here.
	 * @param callback
	 * @param attributes. Deprecated, will get ignored if using an event object as first param.
	 */
	public sendScreenEvent = (
		event: SendScreenEventInput,
		callback?: any,
		attributes?: any,
	): Promise<void> => {
		let screenName;
		let screenAttributes;
		let screenContainers;
		let screenTags;
		if (typeof event === 'object') {
			/* This is for retrocompatibility */
			screenName = event.name;
			screenAttributes = event.attributes;
			screenContainers = event.containers;
			screenTags = event.tags;
		} else {
			screenName = event;
			screenAttributes = attributes;
		}

		validateScreenEvent(screenName);
		validateContainers(screenContainers);
		const builtEvent = buildScreenEvent(
			this._internalProductInfo,
			this._tenantInfo,
			this._userInfo,
			screenAttributes,
			// TODO: Remove the as any and move into a place where we know event is an object
			(event as any).nonPrivacySafeAttributes,
			screenTags,
			this._tabTracking.getCurrentTabId(),
			this._sessionTracking.getCurrentSessionId(),
			this._task.getAllTaskSessions(),
			this._orgInfo,
			this._pageLoadId,
			this._workspaceInfo,
			event,
			screenContainers,
			this._aliases,
			this._groups,
		);

		const builtEventWithName = {
			name: screenName,
			...builtEvent,
		};

		this._setLastScreenEvent(builtEventWithName);

		return this._processEvent(
			screenName,
			builtEventWithName,
			this._context,
			wrapCallback(callback, builtEventWithName),
		);
	};

	public sendTrackEvent = (event: TrackEventPayload, callback?: any): Promise<void> => {
		validateTrackEvent(event);
		const builtEvent = buildActionEvent(
			this._internalProductInfo,
			this._tenantInfo,
			this._userInfo,
			event,
			eventType.TRACK,
			this._tabTracking.getCurrentTabId(),
			this._sessionTracking.getCurrentSessionId(),
			this._task.getAllTaskSessions(),
			this._orgInfo,
			this._pageLoadId,
			this._workspaceInfo,
			this._aliases,
			this._groups,
		);

		return this._processEvent(
			buildActionName(event),
			builtEvent,
			this._context,
			wrapCallback(callback, builtEvent),
		);
	};

	public sendUIEvent = (event: UIEventPayload, callback?: any): Promise<void> => {
		validateUIEvent(event);
		const builtEvent = buildActionEvent(
			this._internalProductInfo,
			this._tenantInfo,
			this._userInfo,
			event,
			eventType.UI,
			this._tabTracking.getCurrentTabId(),
			this._sessionTracking.getCurrentSessionId(),
			this._task.getAllTaskSessions(),
			this._orgInfo,
			this._pageLoadId,
			this._workspaceInfo,
			this._aliases,
			this._groups,
		);

		return this._processEvent(
			buildActionName(event),
			builtEvent,
			this._context,
			wrapCallback(callback, builtEvent),
		);
	};

	public sendOperationalEvent = (event: OperationalEventPayload, callback?: any): Promise<void> => {
		validateOperationalEvent(event);
		const builtEvent = buildActionEvent(
			this._internalProductInfo,
			this._tenantInfo,
			this._userInfo,
			event,
			eventType.OPERATIONAL,
			this._tabTracking.getCurrentTabId(),
			this._sessionTracking.getCurrentSessionId(),
			this._task.getAllTaskSessions(),
			this._orgInfo,
			this._pageLoadId,
			this._workspaceInfo,
			this._aliases,
			this._groups,
		);

		return this._processEvent(
			buildActionName(event),
			builtEvent,
			this._context,
			wrapCallback(callback, builtEvent),
		);
	};

	public startUIViewedEvent = (callback?: any) => {
		this.stopUIViewedEvent();

		this._uiViewedEvent = new UIViewedEvent(
			this._internalProductInfo,
			() => ({
				embeddedProduct: this._internalProductInfo.embeddedProduct(),
				subproduct: this._internalProductInfo.subproduct(),
				tenantIdType: this._tenantInfo.tenantIdType,
				tenantId: this._tenantInfo.tenantId,
				userId: this._userInfo.userId,
				lastScreenEvent: this._getLastScreenEvent(),
				attributes: this._uiViewedAttributes,
			}),
			(event: any) => this.sendUIEvent(event, callback),
		);
		this._uiViewedEvent.start();
	};

	public stopUIViewedEvent = () => {
		if (this._uiViewedEvent) {
			this._uiViewedEvent.stop();
			this._uiViewedEvent = null;
		}
	};

	public resetUIViewedTimers = () => {
		if (this._uiViewedEvent) {
			this._uiViewedEvent.resetTimers();
		}
	};

	public startApdexEvent = (apdexEvent: any) => {
		this._apdexEvent.start(apdexEvent);
	};

	public getApdexStart = (apdexEvent: any) => this._apdexEvent.getStart(apdexEvent);

	public stopApdexEvent = (apdexEvent: any, callback?: any) => {
		this._apdexEvent.stop(apdexEvent, callback);
	};

	// TODO If we ever make another breaking change, merge these two optional args into an `options` object arg.
	public startLowPriorityEventDelay = (
		timeout?: number,
		callback?: (reason: StopLowPriorityEventDelayReason) => void,
	) => {
		this._eventDelayQueue.startLowPriorityEventDelay(timeout, callback);
	};

	public stopLowPriorityEventDelay = () => {
		this._eventDelayQueue.stopLowPriorityEventDelay();
	};

	public onEvent = (_analyticsId: any, analyticsData: any): Promise<void> => {
		if (!analyticsData) {
			throw new Error('Missing analyticsData');
		}

		if (!analyticsData.eventType) {
			throw new Error('Missing analyticsData.eventType');
		}

		if (analyticsData.eventType === eventType.TRACK) {
			return this.sendTrackEvent(analyticsData);
		} else if (analyticsData.eventType === eventType.UI) {
			return this.sendUIEvent(analyticsData);
		} else if (analyticsData.eventType === eventType.OPERATIONAL) {
			return this.sendOperationalEvent(analyticsData);
		} else if (analyticsData.eventType === eventType.SCREEN) {
			return this.sendScreenEvent(analyticsData.name, null, analyticsData.attributes);
		} else if (analyticsData.eventType === eventType.IDENTIFY) {
			return this.sendIdentifyEvent(analyticsData.userIdType, analyticsData.userId);
		}
		throw new Error(
			`Invalid analyticsData.eventType '${analyticsData.eventType}', ` +
				`must be an eventType: [${objectValues(eventType)}]`,
		);
	};

	public setResponseCallback = (responseCallback: ResponseCallback<AnalyticsResponse>) => {
		this._eventProcessor.setResponseCallback(responseCallback);
	};

	public setEventValidationMode(eventValidationMode: validationMode) {
		this._context.context.validationMode = eventValidationMode;
	}

	/**
	 * @private
	 * @deprecated
	 * Please avoid using internal properties. They are not part of the public
	 * API and may change without notice.
	 *
	 * This property has been exposed for backward compatibility with existing
	 * tests in external code
	 */
	public get task() {
		return this._task;
	}

	/**
	 * @private
	 * @deprecated
	 * Please avoid using internal properties.
	 * They are not part of the public API and may change without notice.
	 *
	 * This property has been exposed for backward compatibility with existing
	 * tests in external code
	 */
	public get _productInfo() {
		return this._internalProductInfo;
	}
}
