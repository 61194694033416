/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { markErrorAsHandled } from '@confluence/graphql';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { isUnauthorizedError, isNotFoundError, Attribution } from '@confluence/error-boundary';
import { getMonitoringClient } from '@confluence/monitoring';
import { useSessionData } from '@confluence/session-data';

import type { useCompanyHubPremiumGateCompanyHubPremiumGateQuery as useCompanyHubPremiumGateCompanyHubPremiumGateQuery$data } from './__types__/useCompanyHubPremiumGateCompanyHubPremiumGateQuery';

export const useCompanyHubPremiumGate = ({ skip = false } = {}) => {
	const { edition } = useSessionData();

	const shouldSkip = skip || edition === ConfluenceEdition.PREMIUM;
	const { data, error } = useQuery<useCompanyHubPremiumGateCompanyHubPremiumGateQuery$data>(
		gql`
			query useCompanyHubPremiumGateCompanyHubPremiumGateQuery {
				entitlements {
					companyHub {
						isEntitled
					}
				}
			}
		`,
		{
			skip: shouldSkip,
		},
	);

	if (error) {
		if (!(isUnauthorizedError(error) || isNotFoundError(error))) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.COMPANY_HUB,
			});
		}
		markErrorAsHandled(error);
	}

	return !shouldSkip && !data?.entitlements.companyHub.isEntitled;
};
