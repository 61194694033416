import { query } from '@confluence/query-preloader-tools';
import { SYSTEM_CONTENT_BYLINE } from '@confluence/web-item-location/entry-points/WEB_FRAGMENT_LOCATIONS';
import { preloadWebItemLocation } from '@confluence/web-item-location/entry-points/preloadWebItemLocation';
import {
	preloadCollabDraftContent,
	updateCollabDraftContent,
} from '@confluence/load-edit-page/entry-points/preloadNativeEditor';
import { FORGE_MODULE_BYLINE } from '@confluence/forge-ui/entry-points/ForgeModuleType';
import { preloadUseExtensionList } from '@confluence/forge-ui/entry-points/preloadUseExtensionList';
import {
	preloadMentionsUsersFromADF,
	preloadMentionsProvider,
} from '@confluence/fabric-media-support/entry-points/preloadMentions';
import { preloadReadTime } from '@confluence/read-time/entry-points/preloadReadTime';
import { fg } from '@confluence/feature-gating';

import { LivePageUnifiedQuery } from './LivePageUnifiedQuery.graphql';
import type {
	LivePageUnifiedQuery as LivePageUnifiedQueryType,
	LivePageUnifiedQueryVariables,
} from './__types__/LivePageUnifiedQuery';

export type PreloadLivePageDataParams = {
	userId: string | null;
	contentId: string;
	spaceKey: string;
};

/**
 * We combine what we can into a single unified query.
 * More complex preloaders will be split out for now.
 */
export function preloadLivePageData({ userId, contentId, spaceKey }: PreloadLivePageDataParams) {
	const preloaders: Array<Promise<any>> = [];

	// 1. Editor data

	// specific native collab editor queries
	if (!!contentId) {
		if (fg('cc_mention_hydrate_adf')) {
			preloaders.push(preloadCollabDraftContent(contentId, '', false));
		} else {
			const draftPromise = preloadCollabDraftContent(contentId, '', false).then(async (draft) => {
				const originalDocument = draft?.data?.collabDraft?.document;
				if (!originalDocument) {
					return;
				}

				// IMPORTANT: previously, Confluence draft had mentions populated, which allowed to
				// render them in the editor without additional requests. This is no longer the case
				// with NCS-based drafts, so we need to preload mentions and mutate the draft to match
				// the old behavior. This is temporary until we "hydrate" mentions at cc-graphql level.
				const document = await preloadMentionsUsersFromADF(originalDocument);
				if (document === originalDocument) {
					return;
				}

				return updateCollabDraftContent(contentId, '', {
					...draft.data,
					collabDraft: {
						...draft.data?.collabDraft,
						document,
					},
				});
			});

			preloaders.push(draftPromise);
		}
		preloaders.push(preloadMentionsProvider());
	}

	if (fg('confluence_frontend_extension_points_in_live_pages')) {
		preloaders.push(
			// Pre-loading apps in the byline area for live pages. Currently only live pages have extension point apps and not classic editor.
			preloadWebItemLocation({ contentId, location: SYSTEM_CONTENT_BYLINE }),
			// For some reason the addition of the filter (uses new Forge query) is causing SSR to timeout - workaround by skipping the filter
			preloadUseExtensionList({
				spaceKey: spaceKey || '',
				moduleType: FORGE_MODULE_BYLINE,
				skipFilter: true,
			}),
		);
	}

	if (fg('live_pages_byline_read_time')) {
		preloaders.push(preloadReadTime(contentId, false));
	}

	// 2. Unified data
	return Promise.all([
		query<LivePageUnifiedQueryType, LivePageUnifiedQueryVariables>({
			query: LivePageUnifiedQuery,
			variables: {
				userId,
				contentId,
				spaceKey,
				isUsdSsr: fg('confluence_frontend_usd_ssr'),
				shouldIncludePermissionType: fg('confluence_team_presence_general_availability'),
			},
			fetchPolicy: 'network-only',
		}),
		...preloaders,
	]);
}
