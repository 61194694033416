import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import {
	preloadSideNavigation,
	preloadSideNavigationNeedsContentId,
} from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceViews } from '@confluence/space-views/entry-points/preloadSpaceViews';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';
import { preloadBlogTree } from '@confluence/blog-tree/entry-points/preloadBlogTree';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';

export const preloadNavigationTasks = async (
	spaceKey: string,
	contentId: string,
	isLicensed: boolean,
	isBlog: boolean,
) => {
	const tasks: Promise<any>[] = [];
	tasks.push(
		preloadSpaceGuestList(spaceKey),
		preloadShortcutsSmartLinks(spaceKey),
		preloadSideNavigation(spaceKey),
		preloadSideNavigationNeedsContentId(spaceKey, contentId),
		preloadSpaceViews(spaceKey, isLicensed),
	);

	tasks.push(
		preloadPageTree({
			spaceKey,
			...(isBlog ? {} : { contentId }),
			statuses: PAGE_TREE_STATUSES,
			isSuperAdmin: false,
		}),
		preloadBlogTree({ spaceKey }),
	);

	return Promise.all(tasks);
};
