import type { MessageDescriptor } from 'react-intl-next';

import { messages } from './messages';

export type WhiteboardSlashCommand = {
	/**
	 * Editor extension key - unique per slash command
	 */
	key: string;
	/**
	 * Internationalised title shown to user as title of slash command menu item
	 */
	title: MessageDescriptor;
	/**
	 * Internationalised description shown to user in slash command menu item
	 */
	description: MessageDescriptor;
	/**
	 * A known template key supported by whiteboards
	 * @see {@link https://developer.atlassian.com/cloud/confluence/rest/v2/api-group-whiteboard/#api-whiteboards-post-request}
	 */
	templateKey?: string;
};

export const slashCommands: WhiteboardSlashCommand[] = [
	{
		key: 'whiteboard',
		title: messages.createWhiteboardTitle,
		description: messages.createWhiteboardDescription,
	},
	{
		key: 'retrospective',
		title: messages.createWhiteboardRetrospectiveTitle,
		description: messages.createWhiteboardRetrospectiveDescription,
		templateKey: 'simple-retrospective',
	},
	{
		key: 'diagram',
		title: messages.createWhiteboardDiagramTitle,
		description: messages.createWhiteboardDiagramDescription,
		templateKey: 'flow-chart',
	},
	{
		key: 'flowchart',
		title: messages.createWhiteboardFlowchartTitle,
		description: messages.createWhiteboardFlowchartDescription,
		templateKey: 'flow-chart',
	},
	{
		key: 'brainstorming',
		title: messages.createWhiteboardBrainstormingTitle,
		description: messages.createWhiteboardBrainstormingDescription,
		templateKey: 'brainwriting',
	},
	{
		key: 'roadmap',
		title: messages.createWhiteboardRoadmapTitle,
		description: messages.createWhiteboardRoadmapDescription,
		templateKey: 'product-roadmap',
	},
];
