/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum RoleAssignmentPrincipalType {
  ACCESS_CLASS = "ACCESS_CLASS",
  GROUP = "GROUP",
  TEAM = "TEAM",
  USER = "USER",
}

export interface RoleAssignment {
  principal: RoleAssignmentPrincipalInput;
  roleId: string;
}

export interface RoleAssignmentPrincipalInput {
  principalId: string;
  principalType: RoleAssignmentPrincipalType;
}

export interface SetSpaceRoleAssignmentsInput {
  spaceId: any;
  spaceRoleAssignmentList: RoleAssignment[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSpaceRoleAssignmentsMutation
// ====================================================

export interface UpdateSpaceRoleAssignmentsMutation_setSpaceRoleAssignments_errors_extensions {
  errorType: string | null;
  statusCode: number | null;
}

export interface UpdateSpaceRoleAssignmentsMutation_setSpaceRoleAssignments_errors {
  message: string | null;
  extensions: (UpdateSpaceRoleAssignmentsMutation_setSpaceRoleAssignments_errors_extensions | null)[] | null;
}

export interface UpdateSpaceRoleAssignmentsMutation_setSpaceRoleAssignments {
  errors: UpdateSpaceRoleAssignmentsMutation_setSpaceRoleAssignments_errors[] | null;
  success: boolean;
}

export interface UpdateSpaceRoleAssignmentsMutation {
  setSpaceRoleAssignments: UpdateSpaceRoleAssignmentsMutation_setSpaceRoleAssignments | null;
}

export interface UpdateSpaceRoleAssignmentsMutationVariables {
  input: SetSpaceRoleAssignmentsInput;
}
