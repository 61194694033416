import uuid from 'uuid/v4';

import { type Logger } from '../resiliencedb';
import getBatchableQueue, {
	type BatchableQueue,
	type BatchFlushCallback,
	type RetryQueueOptions,
} from '../resilienceQueue';
import getMetricsCollector, { type MetricsCollector } from '../resilienceQueue/Metrics';
import { type Context, type EventOverrides, type TenantInfo, type UserInfo } from '../types';
import { attachXidToMultipleEvents, type XIDPromise } from '../xid';

import { DEFAULT_REQUEST_TIMEOUT } from './defaults';
import { HTTP_STATUS_CODE_MAP } from './HttpStatusCode';
import { IMetadataClient } from './metadataClient';
import { sendEvents } from './sendEvents';
import {
	ALLOW_LISTED_FETCH_ERROR_NAMES,
	type BaseSegmentEvent,
	FetchError,
	type LibraryMetadataDef,
	type PackagedEvent,
	type SegmentBatchDef,
	type SegmentEvent,
	SegmentEventTypes,
	type SegmentIdentifyEventDef,
	type SegmentIdentifyEventTraitsDef,
	type SegmentProperties,
	type SegmentScreenEventDef,
	type SegmentTrackEventDef,
	type SegmentTrackPropertiesDef,
} from './types';
import User from './user';
import { buildContext, prepareEventProperties } from './util';

export type Options = {
	apiHost: string;
	apiHostProtocol: string;
	retryQueueOptions?: RetryQueueOptions;
	retryQueuePrefix: string;
	product: string;
	requestTimeout?: number;
	xidPromiseGetter: () => ReturnType<typeof XIDPromise>;
	logger?: Logger;
	metadataClient: IMetadataClient;
	disableCookiePersistence?: boolean;
	responseCallback: ResponseCallback<AnalyticsResponse>;
	env: string;
	perimeter?: string;
	enableMetadataCalls?: boolean;
	enableEventCalls?: boolean;
};

class AnalyticsAPIResponse {
	success: boolean;
	validationErrors: Map<string, string>;
	message: Message;
	code: number;
	validationReports?: EventValidationReport[] | null | undefined;

	constructor(
		success: boolean,
		validationErrors: Map<string, string>,
		message: Message,
		code: number,
		validationReports?: EventValidationReport[] | null | undefined,
	) {
		this.success = success;
		this.validationErrors = validationErrors;
		this.message = message;
		this.code = code;
		this.validationReports = validationReports;
	}

	public isSuccess(): boolean {
		return this.success;
	}

	public getValidationErrors(): Map<string, string> {
		return this.validationErrors;
	}

	public getMessage(): Message {
		return this.message;
	}

	public getCode(): number {
		return this.code;
	}

	public getValidationReports(): EventValidationReport[] | null {
		return this.validationReports === undefined ? null : this.validationReports;
	}
}

class Message {
	error: string;

	constructor(error: string) {
		this.error = error;
	}
}

class EventValidationReport {
	messageId: string;
	results: EventValidationResult[];

	constructor(messageId: string, results: EventValidationResult[]) {
		this.messageId = messageId;
		this.results = results;
	}

	public getMessageId(): string {
		return this.messageId;
	}

	public getResults(): EventValidationResult[] {
		return this.results;
	}
}

export class AnalyticsResponse {
	public success: boolean;
	public statusCode: number;
	public message: string;
	public eventResponseList: EventResponse[];

	constructor(
		success: boolean,
		statusCode: number,
		message: string,
		eventResponseList: EventResponse[],
	) {
		this.success = success;
		this.statusCode = statusCode;
		this.message = message;
		this.eventResponseList = eventResponseList;
	}

	public isSuccess(): boolean {
		return this.success;
	}

	public getStatusCode(): number {
		return this.statusCode;
	}

	public getMessage(): string {
		return this.message;
	}

	public getEventResponseList(): EventResponse[] {
		return this.eventResponseList;
	}
}

class EventResponse {
	private messageId: string;
	private results: EventValidationResult[];
	private segmentEvent: SegmentEvent;

	constructor(messageId: string, results: EventValidationResult[], segmentEvent: SegmentEvent) {
		this.messageId = messageId;
		this.results = results;
		this.segmentEvent = segmentEvent;
	}

	public getMessageId(): string {
		return this.messageId;
	}

	public getResults(): EventValidationResult[] {
		return this.results;
	}

	public getSegmentEvent(): SegmentEvent {
		return this.segmentEvent;
	}
}

class EventValidationResult {
	private type: string;
	private severity: string;
	private propertyPath: string;
	private message: string;

	constructor(type: string, severity: string, propertyPath: string, message: string) {
		this.type = type;
		this.severity = severity;
		this.propertyPath = propertyPath;
		this.message = message;
	}

	public getType(): string {
		return this.type;
	}

	public getSeverity(): string {
		return this.severity;
	}

	public getPropertyPath(): string {
		return this.propertyPath;
	}

	public getMessage(): string {
		return this.message;
	}
}

export type ResponseCallback<AnalyticsResponse> = (response: AnalyticsResponse) => void;

export default class EventProcessor {
	private user: User;
	private options: Required<Options>;

	private resilienceQueue: BatchableQueue<PackagedEvent>;

	private gasv3BatchUrl: string;
	private metrics: MetricsCollector;
	private xidPromiseCallback: ReturnType<typeof XIDPromise>;

	private responseCallback: ResponseCallback<AnalyticsResponse>;

	private metadataClient: IMetadataClient;
	private useUrlFromMetadata: boolean;
	private sendEventsLastErrorReason: string | null;

	constructor(options: Options) {
		this.options = {
			...options,
			requestTimeout: options.requestTimeout || DEFAULT_REQUEST_TIMEOUT,
			retryQueueOptions: options.retryQueueOptions || {},
			logger: options.logger || console,
			disableCookiePersistence: options.disableCookiePersistence || false,
			env: options.env,
			perimeter: options.perimeter || '',
			enableMetadataCalls:
				typeof options.enableMetadataCalls === 'boolean' ? options.enableMetadataCalls : true,
			enableEventCalls:
				typeof options.enableEventCalls === 'boolean' ? options.enableEventCalls : true,
		};
		this.user = new User(this.options?.disableCookiePersistence);

		this.xidPromiseCallback = options.xidPromiseGetter();
		this.gasv3BatchUrl = `${options.apiHostProtocol}://${options.apiHost}/batch`;
		this.metrics = getMetricsCollector();

		this.responseCallback = options.responseCallback;

		this.resilienceQueue = getBatchableQueue(
			options.retryQueuePrefix,
			options.product,
			this.options.retryQueueOptions,
			this.options.logger,
		);

		this.metadataClient = options.metadataClient;
		this.useUrlFromMetadata = true;
		this.sendEventsLastErrorReason = null;

		setInterval(
			() => {
				this.useUrlFromMetadata = true;
			},
			10 * 60 * 1000,
		);

		this.resilienceQueue.start(this.sendEvents);
	}

	getUser(): User {
		return this.user;
	}

	async track(
		eventName: string,
		builtEvent: SegmentTrackPropertiesDef & EventOverrides,
		context: Context,
		callback?: () => void,
	) {
		const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.TRACK, builtEvent);
		const eventWithoutMessageId: Omit<SegmentTrackEventDef, 'messageId'> = {
			...baseEvent,
			type: SegmentEventTypes.TRACK,
			properties: prepareEventProperties(builtEvent),
			event: eventName,
		};
		const event: SegmentTrackEventDef = {
			...eventWithoutMessageId,
			messageId: this.createMessageId(),
		};
		const packagedEvent = this.packageEvent(event);
		await this.enqueueEvent(packagedEvent);
		if (callback) {
			callback();
		}
	}

	async page(
		eventName: string,
		builtEvent: SegmentProperties & EventOverrides,
		context: Context,
		callback?: () => void,
	) {
		const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.PAGE, builtEvent);
		const eventWithoutMessageId: Omit<SegmentScreenEventDef, 'messageId'> = {
			...baseEvent,
			type: SegmentEventTypes.PAGE,
			properties: prepareEventProperties(builtEvent),
			name: eventName,
		};
		const event: SegmentScreenEventDef = {
			...eventWithoutMessageId,
			messageId: this.createMessageId(),
		};
		const packagedEvent = this.packageEvent(event);
		await this.enqueueEvent(packagedEvent);
		if (callback) {
			callback();
		}
	}

	// Segment uses the identifier to update user id which we have already done in the analyticsWebClient.ts
	async identify(
		_identifier: string,
		builtEvent: SegmentIdentifyEventTraitsDef & EventOverrides,
		context: Context,
		callback?: () => void,
	) {
		const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.IDENTIFY, builtEvent);
		const eventWithoutMessageId: Omit<SegmentIdentifyEventDef, 'messageId'> = {
			...baseEvent,
			type: SegmentEventTypes.IDENTIFY,
			traits: prepareEventProperties(builtEvent),
		};
		const event: SegmentIdentifyEventDef = {
			...eventWithoutMessageId,
			messageId: this.createMessageId(),
		};
		const packagedEvent = this.packageEvent(event);
		await this.enqueueEvent(packagedEvent);
		if (callback) {
			callback();
		}
	}

	private async enqueueEvent(packagedEvent: any): Promise<void> {
		if (this.options.enableMetadataCalls) {
			this.metadataClient.start(false); // Start the metadata client if needed
		}
		await this.resilienceQueue.addItem(packagedEvent);
	}

	private buildBaseEvent(
		context: Context,
		type: SegmentEventTypes,
		overrides: EventOverrides,
	): Omit<BaseSegmentEvent, 'messageId'> {
		const clonedContext = prepareEventProperties(context);
		const segmentContext = buildContext(clonedContext);
		return {
			context: segmentContext,
			timestamp: new Date().toISOString(),
			type,
			userId: this.user.getUserId(),
			anonymousId: overrides.anonymousId || this.user.getAnonymousId(),
		};
	}

	private createMessageId(): string {
		return `ajs-${uuid()}`;
	}

	private packageEvent(event: SegmentEvent): PackagedEvent {
		const { apiHost, apiHostProtocol } = this.options;
		return {
			headers: {
				'Content-Type': 'text/plain',
			},
			msg: event,
			url: `${apiHostProtocol}://${apiHost}/${event.type.charAt(0)}`,
		};
	}

	// Using anonymous function so it can have the BatchFlushCallback type associated with it
	// And to allow it to not need bind when passing through to ResilieceQueue.
	public sendEvents: BatchFlushCallback<PackagedEvent> = async (items, callback) => {
		if (this.options.enableEventCalls === false) {
			return;
		}

		const httpRetryCount = this.resilienceQueue.getGlobalRetryCount();
		const metricsPayload = this.metrics.getMetricsPayload();

		const eventsWithXID = await this.attachXIDs(items);

		// Calculating sentAt after the XID generation as this may take some time.
		const sentAt = new Date().toISOString();

		const metadataInfo = await this.metadataClient.getMetadataAsync();

		const preferredBatchUrl = metadataInfo?.url ? metadataInfo.url : this.gasv3BatchUrl;
		const finalBatchUrl = this.useUrlFromMetadata ? preferredBatchUrl : this.gasv3BatchUrl;
		const hasFallenBack =
			preferredBatchUrl === this.gasv3BatchUrl ? null : finalBatchUrl !== preferredBatchUrl;

		const metadata: LibraryMetadataDef = {
			...metricsPayload,
			httpRetryCount,
			lastSendEventsErrorReason: this.sendEventsLastErrorReason,
			isUsingFallbackUrl: hasFallenBack,
			props: metadataInfo?.props || null,
			metadataClientMetrics: this.metadataClient?.metrics || null,
		};

		// Remove default values from metadata to save space
		this.cleanLibraryMetadata(metadata);

		const unpackagedEvents = eventsWithXID.map((item) => {
			item.msg.sentAt = sentAt;
			return item.msg;
		});

		const batchBody: SegmentBatchDef = {
			batch: unpackagedEvents,
			sentAt,
			metadata,
		};

		try {
			this.sendEventsLastErrorReason = null;
			const response = await sendEvents({
				url: finalBatchUrl,
				batch: batchBody,
				timeout: this.options.requestTimeout,
			});
			this.metrics.subtractFromMetrics(metricsPayload);
			callback(null, response);
			await this.invokeLocalResponseCallbackAsync(items, response, null);
		} catch (error) {
			// If there's an error, other than one which indicates a temporary problem with the service,
			// we'll stop using the URL from the metadata and fall back to the default URL. After a time
			// we'll fall forward again in case the problem has been resolved.
			if (error instanceof FetchError && [429, 503].includes(error.statusCode)) {
				// Ignore 429 and 503 errors since they're considered to indicate a temporary
				// issue with the service, rather than a network failure due to blocked requests.
				this.sendEventsLastErrorReason = 'ServerBusyError';
			} else {
				this.useUrlFromMetadata = false;
				this.sendEventsLastErrorReason =
					error instanceof Error && ALLOW_LISTED_FETCH_ERROR_NAMES.includes(error.name)
						? error.name
						: 'Unknown';
			}

			callback(error, null);
			await this.invokeLocalResponseCallbackAsync(items, null, error);
		}
	};

	private cleanLibraryMetadata(obj: Record<string, any>): void {
		// skip if the parameter is not an object
		if (obj === null || typeof obj !== 'object') {
			return;
		}

		for (const key in obj) {
			let value = obj[key];
			// Remove null or undefined values
			if (value === null || value === undefined) {
				delete obj[key];
			}
			// Remove zero-valued numbers
			else if (typeof value === 'number' && value === 0) {
				delete obj[key];
			}
			// Remove empty arrays
			else if (Array.isArray(value)) {
				if (value.length === 0) {
					delete obj[key];
				} else {
					value.forEach((item: any) => {
						this.cleanLibraryMetadata(item);
					});
				}
			}
			// Recurse into objects
			else if (typeof value === 'object') {
				this.cleanLibraryMetadata(obj[key]);
			}
		}
	}

	private async invokeLocalResponseCallbackAsync(
		items: PackagedEvent[] | null,
		response: Response | null,
		error: any | null,
	): Promise<void> {
		const localResponseCallback = this.responseCallback;
		if (localResponseCallback) {
			// If we have a response, we'll parse it and create an AnalyticsAPIResponse from it.
			let analyticsAPIResponse: AnalyticsAPIResponse | null = null;
			if (response) {
				let responseObject: any;

				try {
					responseObject = await response.clone().json();
				} catch (error) {
					// Ignore errors parsing the response, we can't do anything about them
				}

				// Ensure we've got a valid object to work with, filling in any missing
				// properties as best we can.
				responseObject = responseObject || {};
				responseObject.code =
					Number.parseInt(responseObject.code) > 0
						? Number.parseInt(responseObject.code)
						: response.status;
				responseObject.success =
					responseObject.success || (responseObject.code >= 200 && responseObject.code <= 299);

				analyticsAPIResponse = new AnalyticsAPIResponse(
					responseObject.success,
					responseObject.validationErrors || new Map(),
					new Message(responseObject.message?.error || ''),
					responseObject.code,
					responseObject.validationReports || null,
				);
			}

			// If we have an error, but it's not an instance of Error (e.g. it's a string), we'll convert it to an Error object.
			if (error && !(error instanceof Error)) {
				error = new Error(String(error));
			}

			const analyticsResponse: AnalyticsResponse = BatchInterceptor.toAnalyticsResponse(
				items || [],
				error || null, // In case 'error' were to be undefined we'll convert it to null.
				analyticsAPIResponse,
			);

			localResponseCallback(analyticsResponse);
		}
	}

	private async attachXIDs(items: PackagedEvent[]): Promise<PackagedEvent[]> {
		if (this.xidPromiseCallback) {
			return attachXidToMultipleEvents(items, this.xidPromiseCallback);
		}
		return Promise.resolve(items);
	}

	public setResponseCallback(responseCallback: ResponseCallback<AnalyticsResponse>) {
		this.responseCallback = responseCallback;
	}

	public refreshMetadata(userInfo: UserInfo | null, tenantInfo: TenantInfo | null) {
		this.metadataClient.userInfo = userInfo;
		this.metadataClient.tenantInfo = tenantInfo;
	}
}

class BatchInterceptor {
	private static readonly http_error_regex = /HTTP Error\s([0-9]{3})\s\((.+)\)/;
	public static toAnalyticsResponse(
		items: PackagedEvent[],
		error: Error | null,
		analyticsAPIResponse: AnalyticsAPIResponse | null,
	): AnalyticsResponse {
		const eventResponseList: EventResponse[] = [];
		if (analyticsAPIResponse !== null) {
			const [statusCode, success] = [analyticsAPIResponse.code, analyticsAPIResponse.success];
			if (statusCode >= 200 && statusCode <= 299) {
				analyticsAPIResponse.validationReports?.forEach((report: EventValidationReport) => {
					const matchedEvent = items.find(
						(item: PackagedEvent) => item.msg.messageId === report.messageId,
					);
					if (matchedEvent) {
						eventResponseList.push(
							new EventResponse(matchedEvent.msg.messageId, report.results, matchedEvent.msg),
						);
					}
				});
				return new AnalyticsResponse(
					success,
					statusCode,
					HTTP_STATUS_CODE_MAP.get(statusCode) as string,
					eventResponseList,
				);
			} else if (statusCode >= 400 && statusCode <= 499) {
				// not exluding "HTTP 429 Too Many Requests error" as it is thrown as an error and handled below
				const errorMessage =
					analyticsAPIResponse.message?.error !== null
						? analyticsAPIResponse.message.error
						: HTTP_STATUS_CODE_MAP.has(statusCode)
							? HTTP_STATUS_CODE_MAP.get(statusCode)
							: HTTP_STATUS_CODE_MAP.get(-1);
				items.forEach((item: PackagedEvent) => {
					eventResponseList.push(new EventResponse(item.msg.messageId, [], item.msg));
				});
				return new AnalyticsResponse(
					success,
					statusCode,
					errorMessage as string,
					eventResponseList,
				);
			}
		} else if (error?.message) {
			const match = error.message.match(BatchInterceptor.http_error_regex);
			if (match) {
				items.forEach((item: PackagedEvent) => {
					eventResponseList.push(new EventResponse(item.msg.messageId, [], item.msg));
				});
				return new AnalyticsResponse(false, Number(match[1]), match[2], eventResponseList);
			}
		}
		items.forEach((item: PackagedEvent) => {
			eventResponseList.push(new EventResponse(item.msg.messageId, [], item.msg));
		});
		return new AnalyticsResponse(
			false,
			-1,
			HTTP_STATUS_CODE_MAP.get(-1) as string,
			eventResponseList,
		);
	}
}
