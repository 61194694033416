import gql from 'graphql-tag';

export const BulkRemoveRoleAssignmentFromSpacesMutation = gql`
	mutation BulkRemoveRoleAssignmentFromSpaces(
		$principalId: ID!
		$principalType: RoleAssignmentPrincipalType!
		$spaceTypes: [BulkRoleAssignmentSpaceType]!
	) {
		bulkRemoveRoleAssignmentFromSpaces(
			input: {
				principal: { principalId: $principalId, principalType: $principalType }
				spaceTypes: $spaceTypes
			}
		) {
			taskId
			success
			errors {
				message
			}
		}
	}
`;
