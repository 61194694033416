/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These imports are for client side use only
#
# Generate source: local/cli/src/commands/placements/generate-placement-files.ts
*/

import { asMessageRenderer } from '@post-office/placement-common';
import growth from '@post-office/team-export--growth/placements/in-app/screen-space-flags/render';
import jiraPandora from '@post-office/team-export--jira-pandora/placements/in-app/screen-space-flags/render';
import jiraTanuki from '@post-office/team-export--jira-tanuki/placements/in-app/screen-space-flags/render';
import marketing from '@post-office/team-export--marketing/placements/in-app/screen-space-flags/render';
import mercury from '@post-office/team-export--mercury/placements/in-app/screen-space-flags/render';
import playground from '@post-office/team-export--playground/placements/in-app/screen-space-flags/render';
import postOfficeTemplate from '@post-office/team-export--post-office-template/placements/in-app/screen-space-flags/render';
import testMessages from '@post-office/team-export--test-messages/placements/in-app/screen-space-flags/render';

export const renderer = { ...growth, ...jiraPandora, ...jiraTanuki, ...marketing, ...mercury, ...playground, ...postOfficeTemplate, ...testMessages };

export const { initializeMessageRenderer, MessageRenderer } = asMessageRenderer(renderer);
