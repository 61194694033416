import uuid from 'uuid/v4';

import { FabricChannel } from '@atlaskit/analytics-listeners/types';
import { ACTION, EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { createUnifiedAnalyticsPayload } from '@atlassian/editor-ai-common/analytics/create-unified-analytics-payload';

import type { UserFlowAnalyticsErrors } from '../../../types/analytics';
import { CONFIG_ITEM_KEYS } from '../../prebuilt/config-items/config-item-keys';
import type { EditorPluginAIConfigItem } from '../../prebuilt/config-items/config-items';

import type {
	AiLifeCycleDynamicAttributesGetter,
	CommonStepAttributes,
	CompleteStep,
	FailedStep,
	PreviewStep,
	ServerErrorStep,
	StepName,
	UserFlowAEP,
	UserFlowStepAttributes,
} from './analyticsFlowTypes';
import { isErrorStepAttributes, type FireAIAnalyticsEventCallback } from './utils';

type FireStepArgs = {
	stepName: StepName;
	attributes?: Partial<UserFlowStepAttributes>;
};

export type AnalyticsFlow = {
	/** The unique identifier for the current user journey */
	aiSessionId: string;
	enqueueStep: (stepArgs: {
		stepName: StepName;
		attributes?: Partial<UserFlowStepAttributes>;
	}) => void;
	fireQueuedStep: (target?: StepName) => void;
	addAttributes: (attributes: Partial<UserFlowStepAttributes>) => void;
	updateCommonAttributes: (attributes: Partial<CommonStepAttributes>) => void;
	getLastAiInteractionId: () => string | undefined;
	getUserFlowCommonAttributes: (
		configItem: EditorPluginAIConfigItem,
	) => Partial<CommonStepAttributes>;
	registerAiLifeCycleDynamicAttributesGetter: (getter: AiLifeCycleDynamicAttributesGetter) => void;
	getAiLifeCycleDynamicAttributes: (args: { init: boolean }) => Record<string, string | number>;
};

export function convertExperienceName(experienceName: string, invokedFrom?: string) {
	// The Proactive Description Completer are using the shared config item key with issue reformatter
	// Need to get the correct experience name from invokedFrom
	// This is temp solution to distinguish two experience
	// We are planning to add a new config item key for Proactive Description Completer
	if (
		invokedFrom === 'jiraProactiveDescriptionCompleterFooterButton' &&
		experienceName === CONFIG_ITEM_KEYS.ADD_STRUCTURE
	) {
		return 'proactiveDescriptionCompleter';
	}
	if (experienceName === CONFIG_ITEM_KEYS.EXPERIMENTAL_PROMPT_PLACEHOLDER) {
		/**
		 * We only check for experiment value here as the config item would not exist
		 * if the experiment is not enabled / is misconfigured.
		 */
		const getExperimentValue = FeatureGates.getExperimentValue;
		const analyticsExperienceName = getExperimentValue(
			'platform_editor_ai-prompts-placeholder',
			'analytics_experience_name',
			'',
		);
		return analyticsExperienceName || experienceName;
	}
	return experienceName;
}

/*
 * make sure to create this flow once for each user journey
 */
export function createAnalyticsFlow({
	fireAIAnalyticsEvent,
	invokeAttributes,
}: {
	fireAIAnalyticsEvent: FireAIAnalyticsEventCallback;
	invokeAttributes: Partial<UserFlowStepAttributes>;
}): AnalyticsFlow {
	const startTime = performance.now();
	const stepTracker: { [stepName: string]: number } = {};
	const aiSessionId = uuid();

	let prevStepEnd = performance.now();
	let prevStepName: StepName;
	let experienceName: string | undefined;
	let adhocAttributes: Partial<UserFlowStepAttributes> = {};
	let commonAttributes: Partial<CommonStepAttributes> = {};
	let dynamicAttributes: Record<string, string | number> = {};
	let aiLifeCycleDynamicAttributesGetter: AiLifeCycleDynamicAttributesGetter;
	let finished = false;
	let contentCopiedSometime = false;
	const sawAupViolation = false;

	let interactionsCounter = 0;
	let lastAiInteractionID: string | undefined;

	let queuedStepArgs: FireStepArgs | undefined = {
		stepName: 'invoke',
		attributes: invokeAttributes,
	};

	/**
	 * A util function to get dynamic attributes for the AI lifecyle
	 * The previously registered getter is invoked when the lifecyle begins
	 * The resolved attributes are then used until the lifecycle ends
	 */
	const getAiLifeCycleDynamicAttributes = ({ init }: { init: boolean }) => {
		if (init && aiLifeCycleDynamicAttributesGetter) {
			dynamicAttributes = aiLifeCycleDynamicAttributesGetter();
		}
		return dynamicAttributes;
	};

	const getAgentAttributes = () => {
		return {
			agentName: commonAttributes.agentName,
			agentId: commonAttributes.agentId,
			agentCreatorType: commonAttributes.agentCreatorType,
			agentExternalConfigReference: commonAttributes.agentExternalConfigReference,
			agentIsDefault: commonAttributes.agentIsDefault,
		};
	};

	const analyticsFlow = {
		aiSessionId,
		/**
		 * Add a step to the queue to be fired on next screen.
		 * @example
		 * ```tsx
		 * const analyticsFlow = useAnalyticsFlow();
		 * analyticsFlow.enqueueStep({ stepName, attributes });
		 * ```
		 */
		enqueueStep: (stepArgs: {
			stepName: StepName;
			attributes?: Partial<UserFlowStepAttributes>;
		}) => {
			// If the user has already finished the experience, we fire an operational error and return
			if (finished) {
				fireAIAnalyticsEvent(
					createErrorPayload({
						aiSessionId,
						errorSubType: 'userFlowAlreadyFinished',
						errorProperties: {
							stepArgs,
							queuedStepArgs,
						},
					}),
				);
				return;
			}

			if (stepArgs.stepName === 'loading') {
				lastAiInteractionID = `${aiSessionId}-${++interactionsCounter}`;
				fireAIAnalyticsEvent({
					payload: createUnifiedAnalyticsPayload({
						action: ACTION.INITIATED,
						interactionID: commonAttributes.channelId ?? lastAiInteractionID,
						experienceName: convertExperienceName(
							experienceName ?? '',
							invokeAttributes.invokedFrom,
						),
						isProactive: false,
						invokedFrom: invokeAttributes.invokedFrom,
						confidenceScore: commonAttributes.confidenceScore,
						attributes: {
							...getAiLifeCycleDynamicAttributes({ init: true }),
							...getAgentAttributes(),
						},
					}),
				});
			}

			if (stepArgs.stepName === 'complete') {
				const attributes = stepArgs?.attributes ?? {};
				const actionTaken = 'actionTaken' in attributes ? attributes.actionTaken : undefined;
				const promptType = 'promptType' in attributes ? attributes.promptType : undefined;
				const refinementCount =
					'refinementCount' in attributes ? attributes.refinementCount : undefined;

				fireAIAnalyticsEvent({
					payload: createUnifiedAnalyticsPayload({
						action: ACTION.ACTIONED,
						interactionID: commonAttributes.channelId ?? lastAiInteractionID,
						experienceName: convertExperienceName(
							experienceName ?? '',
							invokeAttributes.invokedFrom,
						),
						isProactive: false,
						invokedFrom: invokeAttributes.invokedFrom,
						confidenceScore: commonAttributes.confidenceScore,
						attributes: {
							...getAiLifeCycleDynamicAttributes({ init: false }),
							...getAgentAttributes(),
							promptType,
							refinementCount,
							aiResultAction: actionTaken?.toLowerCase(),
						},
						traceIds: commonAttributes.traceIds,
					}),
				});
			}

			// If there is already a queued step, we fire an operational error and return
			if (queuedStepArgs !== undefined) {
				fireAIAnalyticsEvent(
					createErrorPayload({
						aiSessionId,
						errorSubType: 'cannotEnqueueMoreThanOneEvent',
						errorProperties: { stepArgs, queuedStepArgs },
					}),
				);
				return;
			}
			queuedStepArgs = stepArgs;
		},
		fireQueuedStep: (target?: StepName) => {
			// If the user has already finished the experience, we fire an operational error and return
			if (finished) {
				fireAIAnalyticsEvent(
					createErrorPayload({
						aiSessionId,
						errorSubType: 'userFlowAlreadyFinished',
						errorProperties: {
							target,
							queuedStepArgs,
						},
					}),
				);
				return;
			}

			// If there is no queued step, we fire an operational error and return
			if (!queuedStepArgs) {
				fireAIAnalyticsEvent(
					createErrorPayload({
						aiSessionId,
						errorSubType: 'noEventExistsToBeFired',
						errorProperties: { target },
					}),
				);
				return;
			}

			queuedStepArgs.attributes = {
				...queuedStepArgs.attributes,
				...adhocAttributes,
				...commonAttributes,
				invokedFrom: invokeAttributes.invokedFrom,
				confidenceScore: commonAttributes.confidenceScore,
				invokedFor: invokeAttributes.invokedFor,
				triggerMethod: invokeAttributes.triggerMethod,
				target,
			};

			if (target === 'failed' || target === 'apiError') {
				fireAIAnalyticsEvent({
					payload: createUnifiedAnalyticsPayload({
						action: ACTION.ERROR,
						interactionID: commonAttributes.channelId ?? lastAiInteractionID,
						experienceName: convertExperienceName(
							experienceName ?? '',
							invokeAttributes.invokedFrom,
						),
						isProactive: false,
						invokedFrom: invokeAttributes.invokedFrom,
						confidenceScore: commonAttributes.confidenceScore,
						attributes: {
							...getAiLifeCycleDynamicAttributes({ init: false }),
							...getAgentAttributes(),
							aiErrorMessage: isErrorStepAttributes(queuedStepArgs?.attributes)
								? queuedStepArgs?.attributes?.errorKey ?? queuedStepArgs?.attributes?.errorType
								: 'Unknown error',
							aiErrorCode: isErrorStepAttributes(queuedStepArgs.attributes)
								? queuedStepArgs.attributes?.statusCode ?? 500
								: 500,
						},
						traceIds: commonAttributes.traceIds,
					}),
				});
			}

			if (target === 'preview') {
				fireAIAnalyticsEvent({
					payload: createUnifiedAnalyticsPayload({
						action: ACTION.VIEWED,
						interactionID: commonAttributes.channelId ?? lastAiInteractionID,
						experienceName: convertExperienceName(
							experienceName ?? '',
							invokeAttributes.invokedFrom,
						),
						isProactive: false,
						invokedFrom: invokeAttributes.invokedFrom,
						confidenceScore: commonAttributes.confidenceScore,
						attributes: {
							...getAiLifeCycleDynamicAttributes({ init: false }),
							...getAgentAttributes(),
						},
						traceIds: commonAttributes.traceIds,
					}),
				});
			}

			// Add target to attributes and fire the event
			fireUserFlowStep(queuedStepArgs);

			// Remove the queued event
			queuedStepArgs = undefined;
		},
		/**
		 * This is useful when you want to add attributes to the current step
		 * but you don't know them at the time of enqueuing the step.
		 *
		 * It's safe to fire this on dismount -- because the step is enqueued in the dismount
		 * of a parent component (where AnalyticsFlowContextProvider is setup).
		 *
		 * @example
		 * ```tsx
		 * const analyticsFlow = useAnalyticsFlow();
		 * React.useEffect(() => {
		 *  return () => {
		 *    // add attribute on dismount
		 *    analyticsFlow.addAttributes({ loadingTime })
		 *  }
		 * })
		 * ```
		 */
		addAttributes: (attributes: Partial<UserFlowStepAttributes>) => {
			// The user copying the content is being treated as a potential success indicator
			// This is a weak signal as we don't know if the user actually used the copied content
			// and might expect the user to copy the content in cases where the generation failed.
			if ('contentCopied' in attributes && attributes.contentCopied) {
				contentCopiedSometime = true;
			}
			adhocAttributes = {
				...adhocAttributes,
				...attributes,
			};
		},

		getAiLifeCycleDynamicAttributes,

		/**
		 * Editor-AI can be invoked in various contexts.
		 * In some of those scenarios, we want to register additional attributes to understand how the respective feature is performing.
		 *
		 * For example, when the Issue Reformatter is used, we want to understand analytics such as
		 *  - The issue-type of the issue where the issue reformatter was invoked
		 *  - Whether the feature was used from Issue-View or GIC
		 *  - Product type such as JWM and JSW
		 *
		 * This method registers a attribute getter function.
		 * The function will be invoked when the feature is first run (i.e. along with `aiInteraction Initiated`)
		 * The resolved values will then be unchanged until the AI lifecyle is completed
		 * i.e. The same values will be used for all events such as `aiResult Viewed` and `aiResult dismissed`
		 *
		 * In the current version, this method accepts one getter function, and assumes that there aren't multiple functions.
		 * In future, this can be tweaked to allow multiple getters.
		 * Such improvements need to be done carefully so that the getters are also unregistered accordingly
		 */
		registerAiLifeCycleDynamicAttributesGetter: (getter: AiLifeCycleDynamicAttributesGetter) => {
			aiLifeCycleDynamicAttributesGetter = getter;
		},

		/**
		 * Common attributes will be retained for the rest of the analytics flow
		 * until it is updated again
		 *
		 * Example uses: keeping track of which backendModel has been selected
		 */
		updateCommonAttributes: (attributes: Partial<CommonStepAttributes>) => {
			commonAttributes = {
				...commonAttributes,
				...attributes,
			};
		},

		getLastAiInteractionId: () => lastAiInteractionID,

		getUserFlowCommonAttributes: (configItem: EditorPluginAIConfigItem) => {
			return {
				agentName: configItem.agent?.creatorType === 'SYSTEM' ? configItem.agent?.name : undefined,
				agentId: configItem.agent?.id,
				agentCreatorType: configItem.agent?.creatorType,
				agentExternalConfigReference: configItem.agent?.externalConfigReference,
				agentIsDefault: configItem.agent?.isDefault,
			};
		},
	};

	return analyticsFlow;

	function fireUserFlowStep({ stepName, attributes = {} }: FireStepArgs) {
		if (!stepTracker[stepName]) {
			stepTracker[stepName] = 1;
		} else {
			stepTracker[stepName] = stepTracker[stepName] + 1;
		}

		if (attributes.experienceName) {
			experienceName = convertExperienceName(
				attributes.experienceName,
				invokeAttributes.invokedFrom,
			);
		}
		const stepAttributes: UserFlowStepAttributes = {
			...commonAttributes,
			// the duration is currently being overriden in the complete step
			duration: Math.round(performance.now() - prevStepEnd),
			...attributes,
			aiSessionId,
			experienceName,
			source: prevStepName,
			invokedFrom: attributes.invokedFrom,
			confidenceScore: attributes.confidenceScore,
			triggerMethod: attributes.triggerMethod,
			isInvokedFromEmptyPage: attributes.isInvokedFromEmptyPage,
			stepOccurrence: String(stepTracker[stepName]),
			sawAupViolation,
			includeKnowledgeFromCurrentPage: attributes.includeKnowledgeFromCurrentPage,
		};

		// add totalDuration only to the final step
		// and set the experience as finished
		if (!attributes.target) {
			stepAttributes.totalDuration = Math.round(performance.now() - startTime);
			stepAttributes.contentCopiedSometime = contentCopiedSometime;
			finished = true;
		}

		// adding interaction ID to the events coming after the loading step, + final complete step
		if (stepAttributes.source === 'loading' || stepName === 'complete') {
			(
				stepAttributes as
					| PreviewStep['attributes']
					| ServerErrorStep['attributes']
					| FailedStep['attributes']
					| CompleteStep['attributes']
			).aiInteractionID = lastAiInteractionID;
		}

		const payload: UserFlowAEP = {
			action: stepName,
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'userFlow',
			attributes: stepAttributes,
			eventType: EVENT_TYPE.UI,
		};

		//each step is a starting point for measuring duration for next step
		prevStepEnd = performance.now();
		//reset previous step name and custom attributes
		prevStepName = stepName;
		adhocAttributes = {};
		fireAIAnalyticsEvent({
			payload,
		});

		if (finished) {
			fireAIAnalyticsEvent({
				payload: {
					...payload,
					action: 'finished',
					attributes: {
						...payload.attributes,
						// override source to be the previous last step in the flow
						source: stepName,
					},
				},
			});
		}
	}
}

/**
 * Creates an operational error payload
 */
function createErrorPayload<
	ErrorSubtype extends UserFlowAnalyticsErrors['attributes']['errorSubType'],
>({
	errorSubType,
	errorProperties,
}: {
	/**
	 * The userflow operational error subtype
	 */
	errorSubType: ErrorSubtype;

	errorProperties: Omit<
		Extract<UserFlowAnalyticsErrors, { attributes: { errorSubType: ErrorSubtype } }>['attributes'],
		'errorSubType' | 'errorType'
	>;

	aiSessionId: string;
}): {
	payload: UserFlowAnalyticsErrors;
	channel: FabricChannel.editor;
} {
	return {
		payload: {
			action: 'unhandledErrorCaught',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'experienceApplication',
			attributes: {
				errorType: 'userFlowAnalyticsError',
				errorSubType,
				...errorProperties,
			},
			eventType: EVENT_TYPE.OPERATIONAL,
		} as UserFlowAnalyticsErrors,
		channel: FabricChannel.editor,
	};
}
