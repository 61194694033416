import gql from 'graphql-tag';

export const BulkSetRoleAssignmentToSpacesMutation = gql`
	mutation BulkSetRoleAssignmentToSpaces(
		$principalId: ID!
		$principalType: RoleAssignmentPrincipalType!
		$roleId: ID!
		$spaceTypes: [BulkRoleAssignmentSpaceType]!
	) {
		bulkSetRoleAssignmentToSpaces(
			input: {
				roleAssignment: {
					principal: { principalId: $principalId, principalType: $principalType }
					roleId: $roleId
				}
				spaceTypes: $spaceTypes
			}
		) {
			taskId
			success
			errors {
				message
			}
		}
	}
`;
