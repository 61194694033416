import { parse } from 'url';

import { useMemo } from 'react';
import {
	createStore,
	createActionsHook,
	createSubscriber,
	createContainer,
	createStateHook,
} from 'react-sweet-state';
import type { Action } from 'react-sweet-state';
import type { RouteComponentProps } from 'react-router';

import { updatePageloadName } from '@atlaskit/react-ufo/trace-pageload';

import type { Route, RouteMatch } from '@confluence/route';
import type { ParsedUrlQuery } from '@confluence/route-manager-utils';

export type RouteStateType = {
	/**
	 * Returns a string representing a relative URL to the route with query parameters.
	 * This can later be used as an href to return to the current page.
	 */
	getHref: () => string;
	/**
	 * The React Router `history` object, which provides the current URL
	 * and functions to manipulate this session's history.
	 * Typically this should not be accessed directly. Hooks should be used instead.
	 */
	history: RouteComponentProps['history'] | null;
	/**
	 * The React Router `location` object, which provides the current URL
	 * and any other state tracked in React Router for this location.
	 */
	location: RouteComponentProps['location'] | null;
	/**
	 * The Route object that matches the current URL, query params, and hash.
	 */
	match: (Route & RouteMatch) | null;
	/**
	 * transitionId is incremented on every push/pop to distinguish a deliberate
	 * user navigation from an automatic redirect (replace). An id of 0 indicates that
	 * we are currently on the initial load.
	 */
	transitionId: number | null;
	/**
	 * Constructs a URL string based on the specified route `name, query params, and hash.
	 * If `params.href` is specified, that is returned instead.
	 */
	toUrl: (
		name: string | undefined,
		params?: {
			query?: { [key: string]: any };
			hash?: string;
			[key: string]: any;
		},
	) => string;
	/**
	 * Gets the Route object that matches the specified `url` string.
	 */
	matchRoute: (url: string) => (Route & RouteMatch) | null;
	/**
	 * Gets the Route object that matches the specified `url` string,
	 * with an extra check to ensure the `url` is pointing to this instance of Confluence.
	 * Returns null if this `url` points to a different host than the current host.
	 */
	matchSupportedRoute: (url: string) => (Route & RouteMatch) | null;
	/**
	 * Gets the query parameters from the current url.
	 */
	getQueryParams: () => ParsedUrlQuery;
	/**
	 * Updates the query params in the current url using `history.push` by default.
	 * Set `replace` to true to replace the current url instead.
	 */
	setQueryParams: <T extends {} = {}>(query: T, replace?: boolean) => void;
	/**
	 * Gets the hash value from the current url.
	 */
	getHash: () => string;
	/**
	 * Updates the hash value in the current url using `history.replace`.
	 */
	setHash: (hash: string) => void;
	/**
	 * @deprecated Use the `replace` action instead.
	 */
	replace: (url: string, forceReload?: boolean) => void;
	/**
	 * @deprecated Use the `push` action instead.
	 */
	push: (url: string, forceReload?: boolean) => void;
	/**
	 * A list of all of the named routes currently registered in the RouteManager.
	 */
	routes: Route[];
	/**
	 * @deprecated Use `window.open` instead.
	 */
	open: typeof window.open;
	/**
	 * This function should be run before any route transitions that cause a full page reload.
	 * Calls to `push` and `replace` will already call this if `forceReload` is specified.
	 */
	onPageReload: (url: string, isForced: boolean) => void;
	/**
	 * Preloads necessary components, queries, and manifests for the given route `matchOrUrl`.
	 * `matchOrUrl` can be a string or a Route object.
	 */
	preloadRoute: (matchOrUrl: (Route & RouteMatch) | string) => void;
};

export type RouteActionsType = {
	/**
	 * Navigates to the specified `url`
	 * This does save the old URL in the browser history.
	 *
	 * If `forceReload` is true, the SPA will be reloaded at the new URL.
	 */
	push: (url: string, forceReload?: boolean) => Action<RouteStateType, void, void>;
	/**
	 * Replaces the browser's current URL with the specified `url`.
	 * This does not save the current URL in the browser history.
	 * For most navigation, you should use `push`.
	 *
	 * If `forceReload` is true, the SPA will be reloaded at the new URL.
	 */
	replace: (url: string, forceReload?: boolean) => Action<RouteStateType, void, void>;
	/**
	 * Updates the query params in the current url using `history.push` by default.
	 * Set `replace` to true to replace the current url instead.
	 * `query` should be an object mapping all of the param keys that you want to update to their new values,
	 *   You do **not** need to provide old query params within the `query` object.  They are automatically kept.
	 *   Pass `undefined` or `null` as the value within the query object to remove a param.
	 *   It is not currently possible to set multiple values for the same parameter.
	 *   To set multiple params, navigate using `push` or `replace` like this:
	 *   `push("/path?paramKey=one&paramKey=two");`
	 *   This function will currently mangle previously set multi-value params until CCP-4540 is fixed.
	 */
	setQueryParams: (
		query: Record<string, string | undefined | null>,
		replace?: boolean,
	) => Action<RouteStateType, void, void>;
	/**
	 * Updates the hash value in the current url using `history.replace`.
	 */
	setHash: (hash: string) => Action<RouteStateType, void, void>;
	/**
	 * Overrides the name of the UFO experience to the new provided name.
	 *
	 * In general, UFO experience names are the same as *route names*, and you shouldn't use this method for overrides.
	 * In some cases, however, multiple distinct experiences can live on the same route.
	 * A good example is "View Page Experience" and "Live Edit Page Experience".
	 * For such cases, this method is provided.
	 *
	 * Please note, that components that use this method are assuming a sort of "child router" role, in that those components control a part of app routing.
	 *
	 * @param newName the name that the current experience should use.
	 */
	overrideUFORouteName(newName: string): Action<RouteStateType, void, void>;
};
type PrivateRouteActionsType = {
	/**
	 * Returns various data from the current value of the state.
	 * This allows accessing RouteState data without triggering a re-render.
	 */
	getLatestRouteData: () => Action<RouteStateType, void, LazyRouteData>;
};

const nullNoop = () => null;
const objNoop = () => ({});
const stringNoop = () => '';
const voidNoop = () => {};

const RouteStore = createStore<RouteStateType, RouteActionsType & PrivateRouteActionsType>({
	initialState: {
		getHref: stringNoop,
		history: null,
		location: null,
		match: null,
		transitionId: null,
		toUrl: stringNoop,
		matchRoute: nullNoop,
		matchSupportedRoute: nullNoop,
		getQueryParams: objNoop,
		setQueryParams: voidNoop,
		getHash: stringNoop,
		setHash: voidNoop,
		replace: voidNoop,
		routes: [],
		push: voidNoop,
		open: nullNoop,
		onPageReload: voidNoop,
		preloadRoute: voidNoop,
	},
	actions: {
		push(...args) {
			return ({ getState }) => getState().push(...args);
		},
		replace(...args) {
			return ({ getState }) => getState().replace(...args);
		},
		setQueryParams:
			(query, replace) =>
			({ getState }) =>
				getState().setQueryParams(query, replace),
		setHash:
			(hash) =>
			({ getState }) =>
				getState().setHash(hash),
		getLatestRouteData:
			() =>
			({ getState }) => {
				const match = getState().match;

				return {
					routeName: match?.name,
					routeParams: match?.params ?? {},
					hash: getState().location?.hash?.substring(1) ?? '',
					href: getState().getHref(),
					queryParams: parse(getState().location?.search ?? '', true)?.query,
					toUrl:
						match?.toUrl?.bind(match) ??
						function () {
							return '';
						},
				};
			},
		overrideUFORouteName:
			(newName) =>
			({ getState }) => {
				updatePageloadName(newName, getState().match?.name);
			},
	},
	name: 'RouteState',
});

export const RouteStateContainer = createContainer(RouteStore, {
	onInit:
		() =>
		({ setState }, routeState: RouteStateType) => {
			setState(routeState);
		},
	onUpdate:
		() =>
		({ setState }, routeState: RouteStateType) => {
			setState(routeState);
		},
});
export const RouteSubscriber = createSubscriber(RouteStore);

/**
 * `useAllRouteActions` exposes all RouteState actions for internal use.
 * This is used to limit which route actions are publicly exposed.
 */
const useAllRouteActions = createActionsHook(RouteStore);

/**
 * Returns a `RouteActionsType` object containing functions
 * for modifying the current url.
 */
export const useRouteActions = () => {
	const { push, replace, setQueryParams, setHash, overrideUFORouteName } = useAllRouteActions();

	return {
		/** See {@link RouteActionsType.push} for documentation */
		push,
		/** See {@link RouteActionsType.replace} for documentation */
		replace,
		/** See {@link RouteActionsType.setQueryParams} for documentation */
		setQueryParams,
		/** See {@link RouteActionsType.setHash} for documentation */
		setHash,
		/** See {@link RouteActionsType.overrideUFORouteName} for documentation */
		overrideUFORouteName,
	};
};

/**
 * Creates a custom hook that watches only one specific URL route parameter `routeParam`.
 *
 * This hook will trigger a re-render only when that param changes, rather than on every route change.
 * Make sure this is called _outside_ of your component, so the hook does not get recreated every render.
 */
export const createRouteParamHook = (routeParam: string) =>
	createStateHook(RouteStore, {
		selector: (state): string | undefined => state?.match?.params?.[routeParam],
	});

/**
 * Creates a custom hook that watches one specific URL query parameter `queryParam`.
 * This hook returns the first query param if multiple are specified,
 * or `undefined` if the param is not specified.
 *
 * This hook will trigger a re-render only when that param changes, rather than on every route change.
 * Make sure this is called _outside_ of your component, so the hook does not get recreated every render.
 */
export const createSingleQueryParamHook = (queryParam: string) =>
	createQueryParamHook<string | undefined>(queryParam, (parseResult) =>
		singleQueryParamParser(parseResult),
	);

/**
 * Creates a custom hook that watches one specific URL query parameter `queryParam`.
 * This hook returns the first query param if multiple are specified,
 * or `undefined` if the param is not specified.
 *
 * Note that currently, setting multi-value params with `setQueryParams` is broken.
 * CCP-4540 tracks this issue.
 *
 * This hook will trigger a re-render only when that param changes, rather than on every route change.
 * Make sure this is called _outside_ of your component, so the hook does not get recreated every render.
 */
export const createMultiValueQueryParamHook = (queryParam: string) =>
	createQueryParamHook<string[] | undefined>(queryParam, (parseResult) => {
		if (parseResult === '') {
			return [];
		} else if (typeof parseResult === 'string') {
			return [parseResult];
		}
		return parseResult;
	});

/**
 * Creates a custom hook that watches one specific URL query parameter `queryParam`.
 * This hook factory takes a `customParser` function and can return any type of value.
 * The `customParser` function must be able to handle a string or a string array (or undefined).
 * A string array is provided to the `customParser` if the query param is specified multiple times in the URL.
 *
 * This hook will trigger a re-render only when that returned value changes, rather than on every route change.
 * Make sure this is called _outside_ of your component, so the hook does not get recreated every render.
 */
type QueryParamParser<T> = (parseResult: string | string[] | undefined) => T;
export const createQueryParamHook = <T,>(
	queryParam: string,
	customParser: QueryParamParser<T>,
): (() => T) =>
	createStateHook(RouteStore, {
		selector: (state): T => {
			const parseResult = parse(state.location?.search ?? '', true)?.query?.[queryParam];
			return customParser(parseResult);
		},
	});

/**
 * Returns the hash value from the current url, without the `#` character.
 * Returns "" if no hash is specified
 *
 * This hook will trigger a re-render only when the hash value changes.
 */
export const useHash = createStateHook(RouteStore, {
	selector: (state): string => {
		const hash = state.location?.hash;
		return hash ? hash.substring(1) : '';
	},
});

/**
 * Runs the provided `selector` function on the route url and returns its output.
 * This can be used to check if the provided url matches the current url.
 */
export const useRouteUrl: StringSelectorHook = createStateHook(RouteStore, {
	selector: (state, { selector }) => {
		return selector(state.match?.url);
	},
});

/**
 * Runs the provided `selector` function on the route name (specifically on match.name) and returns its output.
 * This can be used to check if the route matches any condition, like
 *   1. If you're on a specific route
 *   2. or if the route name starts with a certain string
 *   3. or if the route name matches a route in a list
 *
 * This is the recommended way to check if we're on a particular route.
 * Route names should be imported from @confluence/named-routes
 * This will only cause a re-render when the hook's value changes.
 */
export const useRouteName: StringSelectorHook = createStateHook(RouteStore, {
	selector: (state, { selector }) => {
		return selector(state.match?.name);
	},
});

function singleQueryParamParser(parseResult: string | string[] | undefined): string | undefined {
	return Array.isArray(parseResult) ? parseResult?.[0] : parseResult;
}

/**
 * Use this function to quickly identify if the user is on a given route
 */
export function useIsOnRoute(route: Route): boolean {
	return useRouteName({ selector: (matchName) => matchName === route.name });
}

type StringSelectorParser<T> = (parseResult: string | undefined) => T;
type StringSelectorHookArgs<T> = {
	selector: StringSelectorParser<T>;
};
type StringSelectorHook = <T>(args: StringSelectorHookArgs<T>) => T;

export type LazyRouteData = {
	routeName: string | undefined;
	routeParams: { [key: string]: string };
	hash: string;
	href: string;
	queryParams: ParsedUrlQuery;
	toUrl: Route['toUrl'];
};

export type RouteDataRef = React.RefObject<LazyRouteData>;

/**
 * Returns a ref that always points to the current value of various RouteState data.
 * This can be used to get data for event handlers or analytics.
 * `ref.current` should NOT be accessed in code that affects render output.
 * Place the `ref` itself, not `ref.current`, in `useEffect` dependency arrays.
 *
 * Returns a `React.RefObject` containing `LazyRouteData`.  Within `ref.current` will be:
 *   - routeName - the internal name for the current route as a string.  The list of all route names can be found in @confluence/named-routes
 *   - routeParams - object mapping route parameter keys to values.
 *   - hash - the hash value in the current URL
 *   - href - a string containing a URL that links to the current page.
 *   - queryParams - an object mapping query parameter keys to value.  If a query parameter is specified multiple times, its value will be an array.  Otherwise, it will be a string.
 */
export const useRouteDataRef = (): RouteDataRef => {
	const { getLatestRouteData } = useAllRouteActions();

	return useMemo(
		() => ({
			get current() {
				return getLatestRouteData();
			},
		}),
		[getLatestRouteData],
	);
};

/**
 * Returns transition id for current route.
 * Initial load has transitionId of 0.
 *
 * This hook will trigger a re-render only when transition id value changes.
 */
export const useTransitionId = createStateHook(RouteStore, {
	selector: (state) => state.transitionId ?? 0,
});

/**
 * Returns a boolean indicating if the current route is a transition.
 * This hook will trigger a re-render only when changing from initial load to a transition.
 */
export const useIsTransition = createStateHook(RouteStore, {
	selector: (state) => !!state.transitionId,
});

export const useHistory = createStateHook(RouteStore, {
	selector: (state) => state.history,
});

export const useMatchRoute = createStateHook(RouteStore, {
	selector: (state) => state.matchRoute,
});

export const usePathnameMatcher: StringSelectorHook = createStateHook(RouteStore, {
	selector: (state, { selector }) => {
		return selector(state.location?.pathname);
	},
});
