import React from 'react';
import PropTypes from 'prop-types';

import UFOSegment from '@atlaskit/react-ufo/segment';

import { PageSegmentLoadStart, PageSegmentLoadMark } from '@confluence/browser-metrics';
import { LoadableLazy } from '@confluence/loadable';
import { CommentPlaceholder } from '@confluence/comment-simple/entry-points/CommentPlaceholder';
import { PAGE_COMMENTS_METRIC } from '@confluence/comments-section/entry-points/pageSegmentLoadMetrics';
import { useCommentCounts, useAllowNestedComments } from '@confluence/comments-hooks';
import { CommentCountsProvider } from '@confluence/comment-context';
import { useSessionData } from '@confluence/session-data';
import {
	useCommentsDataActions,
	useActiveCommentsQuery,
	useActiveInlineCommentsQuery,
} from '@confluence/comments-data';
import { fg } from '@confluence/feature-gating';

/* if handlePageCommentsRender is passed from ViewPageFooterComments we handle
displaying CommentPlaceholder in that component till comments are rendered*/
//@ts-ignore
export const CommentsSectionFabric = LoadableLazy({
	// eslint-disable-next-line react/prop-types
	//@ts-ignore
	loading: ({ handlePageCommentsRender }) => {
		return !handlePageCommentsRender ? <CommentPlaceholder /> : null;
	},
	loader: () =>
		//@ts-ignore
		import(/* webpackChunkName: "loadable-CommentsSectionFabric" */ './CommentsSectionFabric'),
});

//@ts-ignore
export const CommentsSectionLoader = ({ contentId, ...props }) => {
	// This information is available in the CommentsCountContext, but it also needs to be available to the legacy class
	// component so passing in directly from the hook as well
	const { unresolvedInlineCommentsCount } = useCommentCounts(contentId);
	const { cloudId } = useSessionData();
	const allowNestedComments = useAllowNestedComments();
	const commentsDataActions = useCommentsDataActions();

	const { refetch: refetchActiveComments } = useActiveCommentsQuery({
		pageId: contentId,
	});
	const { refetch: refetchActiveInlineComments } = useActiveInlineCommentsQuery({
		pageId: contentId,
		markerRefList: [],
	});

	return (
		// @ts-ignore
		<UFOSegment name="page-comments">
			<CommentCountsProvider contentId={contentId}>
				<PageSegmentLoadStart key={props.contentId} metric={PAGE_COMMENTS_METRIC} isCustomStart />
				<PageSegmentLoadMark markName="pageCommentsRenderStart" metric={PAGE_COMMENTS_METRIC} />
				<CommentsSectionFabric
					{...props}
					// @ts-ignore
					contentId={contentId}
					inlineCommentsCount={unresolvedInlineCommentsCount}
					cloudId={cloudId}
					allowNestedComments={allowNestedComments}
					commentsDataActions={commentsDataActions}
					refetchCommentsPanel={
						fg('confluence_frontend_comments_panel_v2')
							? refetchActiveComments
							: refetchActiveInlineComments
					}
				/>
			</CommentCountsProvider>
		</UFOSegment>
	);
};

CommentsSectionLoader.propTypes = {
	contentId: PropTypes.string.isRequired,
};
