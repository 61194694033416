/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { css, jsx } from '@compiled/react';
import { useState } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import Button from '@atlaskit/button';
import { Text } from '@atlaskit/primitives';

import type { EgressFilteringService } from '@forge/egress';

import ModalDialog, {
	ModalTransition,
	ModalFooter as AKModalFooter,
	ModalBody,
	ModalTitle,
	ModalHeader,
} from '@atlaskit/modal-dialog';

import { token } from '@atlaskit/tokens';
import type { Extension } from '../../../web-client';
import { FORGE_UI_ANALYTICS_CHANNEL } from '../../../analytics';

import { type NavigatePayload, BridgeClientError } from '..';

import { navigate } from './navigate';
import { getOrigin } from './getOrigin';
import { useEgressPermissionService } from '../../../utils/useEgressPermissionService';
import { makeUrl } from './makeUrl';

type ModalState =
	| {
			state: 'open';
			url: URL;
			onConfirm: () => void;
			onCancel: () => void;
	  }
	| {
			state: 'closed';
	  };

const cssActionItem = css({
	flex: '1 0 auto',
	marginTop: 0,
	marginRight: token('space.050', '4px'),
	marginBottom: 0,
	marginLeft: token('space.050', '4px'),
});

export function useNavigation({
	extension,
	push,
}: {
	extension: Pick<Extension, 'properties' | 'egress'>;
	push?: (path: string, state?: any) => void;
}) {
	const [modalState, setModalState] = useState<ModalState>({ state: 'closed' });
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const egressService = useEgressPermissionService('FETCH_CLIENT_SIDE', extension.egress);

	return {
		onNavigate: async ({ type, url: urlString }: NavigatePayload): Promise<void> => {
			const url = makeUrl(urlString);
			const linkType = getLinkType(url, egressService);
			if (linkType === 'invalid' || !url) {
				throw new BridgeClientError(`link is invalid: ${urlString}`);
			}
			if (linkType === 'trusted') {
				navigate(url.href, type, push);
				return;
			}
			const analyticsAttributes = getAnalyticsAttributes(url);
			return new Promise((resolve, reject) => {
				// TODO: remove this event once we have switched over to the new event schema
				// https://ecosystem-platform.atlassian.net/browse/EXT-2196
				createAnalyticsEvent({
					eventType: UI_EVENT_TYPE,
					data: {
						action: 'viewed',
						actionSubject: 'externalLinkModal',
						attributes: analyticsAttributes,
					},
				}).fire(FORGE_UI_ANALYTICS_CHANNEL);
				setModalState({
					state: 'open',
					url,
					onConfirm: () => {
						navigate(url.href, type, push);
						// TODO: remove this event once we have switched over to the new event schema
						// https://ecosystem-platform.atlassian.net/browse/EXT-2196
						createAnalyticsEvent({
							eventType: UI_EVENT_TYPE,
							data: {
								action: 'approved',
								actionSubject: 'externalLinkModal',
								attributes: analyticsAttributes,
							},
						}).fire(FORGE_UI_ANALYTICS_CHANNEL);
						resolve();
					},
					onCancel: () => {
						// TODO: remove this event once we have switched over to the new event schema
						// https://ecosystem-platform.atlassian.net/browse/EXT-2196
						createAnalyticsEvent({
							eventType: UI_EVENT_TYPE,
							data: {
								action: 'cancelled',
								actionSubject: 'externalLinkModal',
								attributes: analyticsAttributes,
							},
						}).fire(FORGE_UI_ANALYTICS_CHANNEL);
						reject(new BridgeClientError('cancelled'));
					},
				});
			});
		},

		getModalJsx: () => {
			if (modalState.state === 'closed') {
				return <ModalTransition />;
			}
			const ModalFooter = () => {
				return (
					<AKModalFooter>
						<div
							// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- to avoid import of css module from @compiled/react
							css={{
								display: 'flex',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							<Button
								css={{ padding: 0, '> span': { margin: 0 } }}
								href="https://go.atlassian.com/forge-help-app-security"
								appearance="subtle-link"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more about app security
							</Button>
							<div
								// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
								css={css({
									display: 'inline-flex',
									marginTop: 0,
									marginRight: token('space.negative.050', '-4px'),
									marginBottom: 0,
									marginLeft: token('space.negative.050', '-4px'),
								})}
							>
								<div css={cssActionItem}>
									<Button
										appearance="subtle"
										onClick={() => {
											setModalState({ state: 'closed' });
											modalState.onCancel();
										}}
									>
										Cancel
									</Button>
								</div>
								<div css={cssActionItem}>
									<Button
										appearance="warning"
										onClick={() => {
											modalState.onConfirm();
											setModalState({ state: 'closed' });
										}}
									>
										Continue
									</Button>
								</div>
							</div>
						</div>
					</AKModalFooter>
				);
			};
			return (
				<ModalTransition>
					<ModalDialog
						onClose={() => {
							setModalState({ state: 'closed' });
							modalState.onCancel();
						}}
					>
						<ModalHeader>
							<ModalTitle appearance="warning">
								{`Opening external page on ${modalState.url.hostname}`}
							</ModalTitle>
						</ModalHeader>

						<ModalBody>
							{extension.properties?.title ? (
								<Text as="p">
									<Text as="strong">{extension.properties.title}</Text> is sending you to an
									external page. Ensure you trust that page before you continue.
								</Text>
							) : (
								<Text as="p">
									<Text as="strong">This app</Text> is sending you to an external page. Ensure you
									trust that page before you continue.
								</Text>
							)}
							{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- to avoid import of css module from @compiled/react */}
							<Text as="p" maxLines={1}>
								{modalState.url.href}
							</Text>
						</ModalBody>
						<ModalFooter />
					</ModalDialog>
				</ModalTransition>
			);
		},
	};
}

const getRootDomain = (url: URL) => {
	const hostnameParts = url.hostname.split('.');
	// Root domain consists of the last two parts of the hostname
	if (hostnameParts.length > 1) {
		return hostnameParts.slice(-2).join('.');
	} else {
		// If there's only one part, return it (e.g., 'localhost')
		return url.hostname;
	}
};

// domains from https://support.atlassian.com/organization-administration/docs/ip-addresses-and-domains-for-atlassian-cloud-products/
const TRUSTED_ROOT_DOMAINS = [
	'atl-paas.net',
	'atlassian.com',
	'atlassian.net',
	'ss-inf.net',
	'jira.com',
	'bitbucket.org',
	'atlassian-us-gov-mod.com',
	'atlassian-us-gov-mod.net',
];

// Exported for testing only
export function getLinkType(
	url: URL | undefined,
	egressService: EgressFilteringService,
): 'external' | 'trusted' | 'invalid' {
	if (!url) {
		return 'invalid';
	}

	if (url.protocol === 'mailto:') {
		return 'trusted';
	}

	try {
		// URL is listed under permissions in manifest
		if (egressService.isValidUrl(url.href)) {
			return 'trusted';
		}

		// URL is from same origin
		if (url.origin === getOrigin()) {
			return 'trusted';
		}

		// URL is under a trusted atlassian domain
		const rootDomain = getRootDomain(url);
		if (TRUSTED_ROOT_DOMAINS.includes(rootDomain)) {
			return 'trusted';
		}

		if (['https:', 'http:'].includes(url.protocol)) {
			return 'external';
		}
	} catch {}
	return 'invalid';
}

// Exported for testing only
export function getAnalyticsAttributes(url: URL): Record<string, any> {
	// Don't count the initial `?` in the query string length.
	const queryStringSize = url.search.length > 1 ? url.search.length - 1 : 0;
	return {
		url: url.href,
		queryStringSize,
	};
}
