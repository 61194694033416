// used for page-load and page-segment-load events
export const FF_ALLOWLIST_FOR_PERF_METRICS = [
	'confluence.ssr.page-statuses',
	'analytics-next-use-modern-context_jira',
	'confluence_ssr_enable_tesseract_stream_endpoint',
	'confluence_use_parcel',
	'confluence_frontend_use_modern_rendering_engine',
	'confluence_ssr_load_after_paint_loadables_as_paint',
	'ssr_end_of_page_recommendation',
	'confluence_ssr_enable_hydration',
	'confluence_react_streaming',
	'confluence_ssr_enable_hydration_home',
	'confluence_preload_forge_viewport_heights',
	'confluence_frontend_vc_observer_initialization',
	'platform_fix_banner_spacer_vc',
];

// In order to reduce BM event payload size, include a FF in the BM payload only if it's in the allow list.
// The following FFs will be included in all types of BM event.
export const ALLOWLIST_FFS_CUSTOM_EVENTS = [];
