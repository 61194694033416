import { EntriesBuffer } from './plugins/lighthouse-metrics/utils/buffer';
import { PerformanceObserverEntryTypes } from './plugins/lighthouse-metrics/const';
import type { LayoutShift } from './plugins/lighthouse-metrics/utils/buffer';

export type LayoutShiftDetails = {
	shift: number;
	hasInsideShift: boolean;
	hasOutsideShift: boolean;
};

export type LayoutShiftStats = {
	totalShift: number;
	entries: LayoutShiftDetails[];
};

/**
 * This is a reverse-engineered type for a React fiber node.
 * We're messing with React internals throughout this file, so it can blow up badly 🫠
 */
type ReactFiberNode = {
	stateNode: React.Component;
	child: ReactFiberNode | undefined;
	sibling: ReactFiberNode | undefined;
	return: ReactFiberNode | undefined;
	elementType?: React.ReactElement & { name?: string; displayName?: string };
	memoizedProps?: {
		attribution?: string;
		__boundaryMarker: boolean;
	};
};

function findFiberNode(domNode: HTMLElement | null): ReactFiberNode | undefined {
	if (!domNode) return undefined;

	const key = Object.keys(domNode).find((k) => k.startsWith('__reactFiber$'));

	return key ? ((domNode as any)[key] as ReactFiberNode) : undefined;
}

function attributionByClosestFiberNode(fiberNode: ReactFiberNode) {
	let current: ReactFiberNode | undefined = fiberNode;
	while (current) {
		if (current.memoizedProps?.__boundaryMarker) {
			return current.memoizedProps?.attribution ?? 'unknown';
		}

		current = current.return;
	}

	return 'not-attributed';
}

function toQuerySelector(node: HTMLElement): string {
	const path = [];
	path.push(node.tagName.toLowerCase());
	if (node.id) {
		path.push(`#${node.id}`);
	}
	if (node.className) {
		path.push(`.${node.className.split(' ').join('.')}`);
	}
	if (node.dataset) {
		Object.entries(node.dataset).forEach(([key, value]) => {
			path.push(`[data-${key}=${value}]`);
		});
	}

	return path.join('');
}

export const getLayoutShiftWithAttribution = (): Record<string, LayoutShiftStats> => {
	const layoutShiftMap = new Map<string, LayoutShiftStats>();
	const shifts = EntriesBuffer[PerformanceObserverEntryTypes.LayoutShift].buffer as LayoutShift[];

	shifts.forEach((shift) => {
		shift.sources.forEach((source) => {
			let hasInsideShift = false;
			let hasOutsideShift = false;

			if (
				source.previousRect.x !== source.currentRect.x ||
				source.previousRect.y !== source.currentRect.y
			) {
				hasOutsideShift = true;
			}

			if (
				source.previousRect.width !== source.currentRect.width ||
				source.previousRect.height !== source.currentRect.height
			) {
				hasInsideShift = true;
			}

			const entry = {
				shift: shift.value,
				hasInsideShift,
				hasOutsideShift,
				element: source.node ? toQuerySelector(source.node) : 'undefined',
			};

			const fiberNode = findFiberNode(source.node);
			const attribution = fiberNode ? attributionByClosestFiberNode(fiberNode) : 'nonReact';

			if (layoutShiftMap.has(attribution)) {
				const stats = layoutShiftMap.get(attribution)!;
				stats.totalShift += shift.value;
				stats.entries.push(entry);
			} else {
				layoutShiftMap.set(attribution, {
					totalShift: shift.value,
					entries: [entry],
				});
			}
		});
	});

	return Object.fromEntries(layoutShiftMap);
};
