/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessStatus {
  ANONYMOUS_ACCESS = "ANONYMOUS_ACCESS",
  EXTERNAL_COLLABORATOR_ACCESS = "EXTERNAL_COLLABORATOR_ACCESS",
  EXTERNAL_SHARE_ACCESS = "EXTERNAL_SHARE_ACCESS",
  LICENSED_ADMIN_ACCESS = "LICENSED_ADMIN_ACCESS",
  LICENSED_USE_ACCESS = "LICENSED_USE_ACCESS",
  NOT_PERMITTED = "NOT_PERMITTED",
  UNLICENSED_AUTHENTICATED_ACCESS = "UNLICENSED_AUTHENTICATED_ACCESS",
}

export enum ConfluenceEdition {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
  STANDARD = "STANDARD",
}

export enum Environment {
  DEVELOPMENT = "DEVELOPMENT",
  PRODUCTION = "PRODUCTION",
  STAGING = "STAGING",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteInformationQuery
// ====================================================

export interface SiteInformationQuery_tenant_editions {
  edition: ConfluenceEdition;
}

export interface SiteInformationQuery_tenant {
  shard: string;
  cloudId: string;
  environment: Environment;
  activationId: string | null;
  editions: SiteInformationQuery_tenant_editions | null;
}

export interface SiteInformationQuery_organization {
  orgId: string | null;
}

export interface SiteInformationQuery_userForAnalytics {
  id: string | null;
}

export interface SiteInformationQuery_user_confluence {
  accessStatus: AccessStatus;
  accountId: string | null;
  userKey: string | null;
  locale: string;
}

export interface SiteInformationQuery_user {
  id: string | null;
  displayName: string | null;
  confluence: SiteInformationQuery_user_confluence | null;
  timeZone: string | null;
}

export interface SiteInformationQuery_getAIConfig {
  isEnabled: boolean;
  isRovoEnabled: boolean | null;
  isRovoLLMEnabled: boolean | null;
}

export interface SiteInformationQuery_confluence_tenantContext_licenseStates_confluence {
  ccpEntitlementId: string | null;
}

export interface SiteInformationQuery_confluence_tenantContext_licenseStates {
  confluence: SiteInformationQuery_confluence_tenantContext_licenseStates_confluence | null;
}

export interface SiteInformationQuery_confluence_tenantContext {
  licenseStates: SiteInformationQuery_confluence_tenantContext_licenseStates | null;
  timeZone: string | null;
}

export interface SiteInformationQuery_siteSettings_homepage {
  uri: string | null;
  title: string | null;
}

export interface SiteInformationQuery_siteSettings_frontCover {
  frontCoverState: string | null;
}

export interface SiteInformationQuery_siteSettings {
  homepage: SiteInformationQuery_siteSettings_homepage | null;
  siteTitle: string;
  showApplicationTitle: boolean;
  frontCover: SiteInformationQuery_siteSettings_frontCover | null;
  companyHubName: string | null;
}

export interface SiteInformationQuery_siteDescription {
  logoUrl: string | null;
}

export interface SiteInformationQuery_currentConfluenceUser {
  isAnonymous: boolean;
}

export interface SiteInformationQuery {
  tenant: SiteInformationQuery_tenant;
  organization: SiteInformationQuery_organization | null;
  userForAnalytics: SiteInformationQuery_userForAnalytics | null;
  user: SiteInformationQuery_user | null;
  getAIConfig: SiteInformationQuery_getAIConfig | null;
  confluence_tenantContext: SiteInformationQuery_confluence_tenantContext | null;
  isSiteAdmin: boolean;
  abTestCohorts: string;
  experimentFeatures: string | null;
  userCanCreateContent: boolean | null;
  isNewUser: boolean;
  siteSettings: SiteInformationQuery_siteSettings | null;
  siteDescription: SiteInformationQuery_siteDescription | null;
  currentConfluenceUser: SiteInformationQuery_currentConfluenceUser | null;
}
