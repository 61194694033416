import { query, isUnauthorizedError } from '@confluence/query-preloader-tools';
import { preloadWebItemLocation } from '@confluence/web-item-location/entry-points/preloadWebItemLocation';
import { preloadBreadcrumbs } from '@confluence/breadcrumbs/entry-points/preloadBreadcrumbs';
import { preloadEditButton } from '@confluence/edit-button/entry-points/preloadEditButton';
import { preloadFabricEditorEligibility } from '@confluence/edit-button/entry-points/preloadFabricEditorEligibility';
import { preloadReadTime } from '@confluence/read-time/entry-points/preloadReadTime';
import { preloadContentAnalyticsViewers } from '@confluence/confluence-analytics/entry-points/preloadContentAnalyticsViewers';
import { preloadPageRestrictionsQuery } from '@confluence/page-restrictions-context/entry-points/preloadPageRestrictionsQuery';
import { preloadGrantAccessDialogData } from '@confluence/grant-access-dialog/entry-points/preloadGrantAccessDialogData';
import { preloadRendererContentState } from '@confluence/content-state/entry-points/preloadRendererContentState';
import { preloadFavouriteButton } from '@confluence/favourite-button/entry-points/preloadFavouriteButton';
import { preloadWatchDialog } from '@confluence/watch-dialog/entry-points/preloadWatchDialog';
import { preloadCommentButton } from '@confluence/comment-button/entry-points/preloadCommentButton';
import { preloadClassificationContextForViewContent } from '@confluence/data-classification/entry-points/queries';
import type { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { preloadConvertPageToFolderBanner } from '@confluence/folder-conversion/entry-points/preloadConvertPageToFolderBanner';
import { fg } from '@confluence/feature-gating';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';

import { ContentHeaderUnifiedQuery } from './ContentHeaderUnifiedQuery.graphql.js';
import { DEFAULT_CONTRIBUTORS_FETCH_LIMIT, CONFLUENCE_EDITION } from './defaultQueryVariables';

export type preloadContentHeaderProps = {
	spaceKey?: string;
	contentId: string;
	isLicensed: boolean;
	userId: string | null;
	username?: string;
	edition: ConfluenceEdition | null;
	isPrevEditRoute?: boolean;
	useNewContentTopper?: boolean;
	isBlog: boolean;
	isLivePageRoute?: boolean;
};

export function preloadContentHeader({
	spaceKey,
	contentId,
	isLicensed,
	userId,
	username,
	edition,
	isPrevEditRoute = false,
	useNewContentTopper = false,
	isBlog,
	isLivePageRoute = false,
}: preloadContentHeaderProps) {
	const queries: Promise<any>[] = [];

	if (!fg('confluence_preload_favourite_button') || !isLivePageRoute) {
		queries.push(preloadFavouriteButton(contentId, userId));
	}

	let hasErrors = false;

	// We preload content header unconditionally but when page is restricted we don't
	// render the corresponding components, neither we use the result of preloading.
	// Hence mute the restriction errors on preloading to avoid unnecessary noise.
	// Not needed on the SSR preloading, as similar errors from server doesn't produce
	// noise in analytics.
	const handlePreloadErrors = (result) => {
		const errorProcessor = window.__GRAPHQL_ERROR_PROCESSOR__;

		hasErrors = Boolean(result?.errors?.length);

		result?.errors?.forEach((error) => {
			if (isUnauthorizedError(error)) {
				errorProcessor?.markErrorAsHandled(error);
			}
		});

		return result;
	};

	// Don't preload queries that will be updated by editor if previous route is edit_page
	if (!isPrevEditRoute) {
		queries.push(
			query({
				query: ContentHeaderUnifiedQuery,
				variables: {
					contentId,
					spaceKey,
					versionOverride: null,
					embeddedContentRender: null,
					limit: DEFAULT_CONTRIBUTORS_FETCH_LIMIT,
					useNewContentTopper,
				},
			}).then(handlePreloadErrors),
			preloadBreadcrumbs(spaceKey, contentId, false).then(handlePreloadErrors),
		);

		// Editor will preload the content state if it's a live page
		if (!isLivePageRoute) {
			queries.push(preloadRendererContentState(contentId).then(handlePreloadErrors));
		}

		// SSR Grant Access Restrictions button
		// username is a legacy URL parameter that actually represents accountId in the grant access flow
		if (contentId && username) {
			queries.push(preloadGrantAccessDialogData(contentId, username));
		}

		//unlike SPA, no need to check the route since preloadContentHeader is fired only when its VIEW_PAGE.
		const includeAncestors = edition === CONFLUENCE_EDITION.FREE;
		queries.push(preloadPageRestrictionsQuery(contentId, includeAncestors));
	}

	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		queries.push(
			preloadWatchDialog({ contentId, isLicensed, userId }),
			preloadEditButton({ spaceKey, contentId }),
			preloadContentAnalyticsViewers(contentId),
			preloadReadTime(contentId, false),
			preloadCommentButton(contentId),
			/**
			 * Inline comment button:
			 * next/packages/ssr-app/src/module-replacements/@confluence/comment-button.js
			 *
			 * "..." button:
			 * See next/packages/ssr-app/src/module-replacements/@confluence/content-tools.js
			 *
			 * Share button:
			 * See next/packages/ssr-app/src/module-replacements/@confluence/share.js
			 */

			// Determine whether to show restriction and share button
			preloadWebItemLocation({
				location: 'system.content.button',
				contentId,
			}),
			preloadClassificationContextForViewContent(contentId, spaceKey),
		);

		if (fg('confluence_preload_fabric_editor_eligibility')) {
			queries.push(preloadFabricEditorEligibility(contentId));
		}

		if (
			spaceKey &&
			!isBlog &&
			!isLivePageRoute &&
			// Company Hub hides its space from the user, is rather presented to the user as "a new surface", not a page so converting it to a folder wouldn't make sense.
			// Additionally, if this query were to execute, it would break the Company Hub history/version preview, because it doesn't respect the versionOverride query parameter.
			!isCompanyHubSpaceKey(spaceKey) &&
			fg('confluence_enable_page_to_folder_conversion_banner')
		) {
			queries.push(preloadConvertPageToFolderBanner({ contentId, spaceKey }));
		}
	} else {
		queries.push(preloadWatchDialog({ contentId, isLicensed, userId }));
	}

	return Promise.all(queries).then((results) => ({
		hasErrors,
		results,
	}));
}
