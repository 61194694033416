import React, { useCallback, useState } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import Button, { LinkButton } from '@atlaskit/button/new';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { OptionData } from '@atlaskit/user-picker';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import ChevronLeftLargeIcon from '@atlaskit/icon/utility/chevron-left';

import { useIsProductAdmin } from '@confluence/current-user';
import {
	SitePermissionTypeFilter,
	SitePermissionType,
	UserAndGroupSearchPicker,
} from '@confluence/user-and-group-search';
import { ADMIN_SITE_SPACE_PERMISSIONS } from '@confluence/named-routes';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { FeedbackCollectorButton } from '@confluence/space-roles/entry-points/FeedbackCollector';
import type { SpaceRole } from '@confluence/space-roles/entry-points/space-role-types';
import { RoleAssignmentPrincipalType } from '@confluence/space-roles/entry-points/space-role-types';

import {
	SpaceSelectionRadioButtons,
	SpaceSelection,
} from './page-components/SpaceSelectionRadioButtons';
import {
	ActionSelectionRadioButtons,
	ActionSelection,
} from './page-components/ActionSelectionRadioButtons';
import { BulkRoleAssignmentSpaceType } from './graphql/__types__/BulkSetRoleAssignmentToSpacesMutation';
import { RoleSelectionSection } from './page-components/RoleSelectionSection';
import { RestrictedView } from './restricted/RestrictedView';
import { i18nBulk } from './BulkSpacePermissionsPage';
import { BulkAccessModal } from './bulk-access-dialogs/BulkAccessModal';
import { useBulkRoleSetAssignment } from './useBulkRoleSetAssignment';

export const ANALYTICS_SOURCE = 'bulkAccessManagementScreen';

const i18n = defineMessages({
	bulkRoleAccessHeader: {
		id: 'bulk-permissions.space.role-access-header',
		defaultMessage: 'Manage space access in bulk',
		description: 'Header text of the bulk role access page',
	},
	userPickerAccessHeader: {
		id: 'bulk-permissions.space.user-picker-access-header',
		defaultMessage: 'Who<asterisk>*</asterisk>',
		description:
			'Header text of the user picker for bulk access management, with an asterisk indicating a required field',
	},
	userPickerPlaceholder: {
		id: 'bulk-permissions.space.user-picker-placeholder',
		defaultMessage: 'Enter a person or group',
		description: 'Placeholder text for the user picker',
	},
});

const bulkAddRolesHeaderStyle = xcss({
	font: token('font.heading.large'),
	paddingBottom: 'space.100',
	paddingTop: 'space.300',
});

const bulkRolesSubtextStyle = xcss({
	font: token('font.body'),
});

const principlePickerHeaderStyle = xcss({
	font: token('font.body.large'),
	fontWeight: token('font.weight.medium'),
	display: 'flex',
	flexDirection: 'row',
	paddingTop: 'space.300',
	paddingBottom: 'space.100',
});

const asteriskStyle = xcss({
	color: 'color.text.accent.red',
});

const principlePickerFineTextStyle = xcss({
	color: 'color.text.accent.gray',
	marginTop: 'space.050',
	font: token('font.body.small'),
});

const contentStyle = xcss({
	marginLeft: 'space.1000',
	marginRight: 'space.1000',
});

const backAndFeedbackButtonsStyle = xcss({
	width: '100%',
	marginLeft: 'space.negative.200',
	paddingTop: 'space.300',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

const principlePickerStyle = xcss({
	paddingBottom: 'space.050',
});

const requirementTextStyle = xcss({
	font: token('font.body'),
	paddingTop: 'space.300',
});
export interface PrincipalOption extends OptionData {
	extra?: any;
}

// Leaving for use once APIs are implemented
// const getPrincipalId = (principal: PrincipalOption | undefined) => {
// 	return principal?.type === 'user' ? principal?.extra?.accountId : principal?.extra?.id;
// };

export const BulkAccessManagementPage = withFlags(
	withErrorBoundary({
		attribution: Attribution.PERMISSIONS_EXPERIENCE,
	})(({ flags }: { flags: FlagsStateContainer }) => {
		const intl = useIntl();
		const { edition } = useSessionData();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { isProductAdmin, isAdminCheckLoading } = useIsProductAdmin();
		const [showDialog, setShowDialog] = useState(false);
		const [stagedPrincipal, setStagedPrincipal] = useState<PrincipalOption | undefined>(undefined);
		const [spaceSelection, setSpaceSelection] = useState<SpaceSelection>(
			SpaceSelection.AllExcludingPersonal,
		);

		const [actionSelection, setActionSelection] = useState<ActionSelection>(
			ActionSelection.AddOrChangeAccess,
		);

		const [roleAssignment, setRoleAssignment] = useState<SpaceRole | null>(null);

		const isRemoval = actionSelection === ActionSelection.RemoveAccess;

		const closeDialog = useCallback(() => {
			setShowDialog(false);
		}, [setShowDialog]);

		const { bulkSetRoleAssignmentToSpaces, bulkRemoveRoleAssignment } = useBulkRoleSetAssignment({
			closeDialog,
			flags,
		});

		const getSpaceTypes = (targetValue) => {
			const spaceTypesArray = [
				BulkRoleAssignmentSpaceType.PERSONAL,
				BulkRoleAssignmentSpaceType.COLLABORATION,
				BulkRoleAssignmentSpaceType.GLOBAL,
				BulkRoleAssignmentSpaceType.KNOWLEDGE_BASE,
			];
			if (targetValue === SpaceSelection.AllExcludingPersonal) {
				return spaceTypesArray.filter((type) => type !== BulkRoleAssignmentSpaceType.PERSONAL);
			} else if (targetValue === SpaceSelection.AllIncludingPersonal) {
				return spaceTypesArray;
			} else if (targetValue === SpaceSelection.OnlyPersonal) {
				return [BulkRoleAssignmentSpaceType.PERSONAL];
			}
		};

		const getPrincipalType = (principalType: OptionData['type']) => {
			if (principalType === 'group') {
				return RoleAssignmentPrincipalType.GROUP;
			} else if (principalType === 'team') {
				return RoleAssignmentPrincipalType.TEAM;
			} else if (principalType === 'custom') {
				return RoleAssignmentPrincipalType.ACCESS_CLASS;
			} else {
				return RoleAssignmentPrincipalType.USER;
			}
		};

		const getPrincipalId = (principal: PrincipalOption) => {
			if (principal?.type === 'group') {
				return principal?.extra?.id;
			} else {
				const idx = principal?.id.indexOf('-');
				return principal?.id.slice(idx + 1);
			}
		};

		const onDialogConfirm = useCallback(async () => {
			closeDialog();
			isRemoval
				? await bulkRemoveRoleAssignment({
						variables: {
							principalId: getPrincipalId(stagedPrincipal!),
							principalType: getPrincipalType(stagedPrincipal!.type),
							spaceTypes: getSpaceTypes(spaceSelection)!,
						},
					})
				: await bulkSetRoleAssignmentToSpaces({
						variables: {
							principalId: getPrincipalId(stagedPrincipal!),
							principalType: getPrincipalType(stagedPrincipal!.type),
							roleId: roleAssignment!.id,
							spaceTypes: getSpaceTypes(spaceSelection)!,
						},
					});
		}, [
			closeDialog,
			isRemoval,
			bulkRemoveRoleAssignment,
			stagedPrincipal,
			spaceSelection,
			bulkSetRoleAssignmentToSpaces,
			roleAssignment,
		]);

		const handleBackToDefaultSpaceAccessClick = useCallback(() => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					actionSubject: 'button',
					action: 'clicked',
					actionSubjectId: 'defaultspaceperms',
					source: ANALYTICS_SOURCE,
				},
			}).fire();
		}, [createAnalyticsEvent]);

		const onSpaceTypeRadioChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				setSpaceSelection(e.target.value as SpaceSelection);
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						actionSubject: 'radio',
						action: 'selected',
						actionSubjectId: 'bulkAccessManagementSpace',
						source: ANALYTICS_SOURCE,
						attributes: {
							spaceSelection: e.target.value,
							isRBACEnabled: true,
						},
					},
				}).fire();
			},
			[createAnalyticsEvent],
		);

		const onActionRadioChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				setActionSelection(e.target.value as ActionSelection);
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						actionSubject: 'radio',
						action: 'selected',
						actionSubjectId: 'bulkAccessManagementAction',
						source: ANALYTICS_SOURCE,
						attributes: {
							actionSelection: e.target.value,
							isRBACEnabled: true,
						},
					},
				}).fire();
			},
			[createAnalyticsEvent],
		);

		//Only passing roleId to the analytics event to avoid leaking UGC
		//Ticket to audit rest of analytics events: https://product-fabric.atlassian.net/browse/RBAC-1552
		const onRoleChange = useCallback(
			(role: SpaceRole) => {
				setRoleAssignment(role);
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						actionSubject: 'role picker',
						action: 'selected',
						actionSubjectId: 'bulkAccessManagement',
						source: ANALYTICS_SOURCE,
						attributes: {
							roleAssignment: role.id,
						},
					},
				}).fire();
			},
			[createAnalyticsEvent],
		);

		const onPrincipalChange = useCallback(
			(received: OptionData | undefined) => {
				// Only send an analytic event if a principal isn't deselected
				if (received) {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							actionSubject: 'principal',
							action: 'selected',
							actionSubjectId: 'bulkAccessManagementPrincipal',
							source: ANALYTICS_SOURCE,
							attributes: {
								principal: received.type,
								isRBACEnabled: true,
							},
						},
					}).fire();
				}
				setStagedPrincipal(received);
			},
			[createAnalyticsEvent],
		);

		const openDialog = useCallback(() => {
			setShowDialog(true);
		}, [setShowDialog]);

		// Don't render the page until we know if the user is a product admin
		// to avoid a flicker of the restricted view.
		if (isAdminCheckLoading) {
			return null;
		}

		if (!isProductAdmin || edition === ConfluenceEdition.FREE) {
			return <RestrictedView />;
		}

		return (
			<>
				<Box xcss={contentStyle}>
					<Box xcss={backAndFeedbackButtonsStyle}>
						<LinkButton
							appearance="subtle"
							onClick={handleBackToDefaultSpaceAccessClick}
							href={ADMIN_SITE_SPACE_PERMISSIONS.toUrl({
								tab: 'allspaces',
							})}
							iconBefore={ChevronLeftLargeIcon}
						>
							{intl.formatMessage(i18nBulk.backToSpacePermissions)}
						</LinkButton>
						<FeedbackCollectorButton source="Bulk Space Roles" />
					</Box>

					<Box xcss={bulkAddRolesHeaderStyle}>{intl.formatMessage(i18n.bulkRoleAccessHeader)}</Box>
					<Box xcss={bulkRolesSubtextStyle}>{intl.formatMessage(i18nBulk.titleSubtext)}</Box>

					<Box xcss={principlePickerHeaderStyle}>
						{intl.formatMessage(i18n.userPickerAccessHeader, {
							asterisk: (chunks: React.ReactNode[]) => <Box xcss={asteriskStyle}>{chunks}</Box>,
						})}
					</Box>
					<Box xcss={principlePickerStyle}>
						<UserAndGroupSearchPicker
							data-test-id="user-and-group-search"
							fieldId="bulk-permissions-user-picker"
							includeGroup
							withUsers
							isMulti={false}
							smart={false}
							placeholder={intl.formatMessage(i18n.userPickerPlaceholder)}
							sitePermissionTypeFilter={SitePermissionTypeFilter.NONE}
							// @ts-ignore - Type 'null' is not assignable to type 'string | ((input: { inputValue: string; }) => string | null)'
							// Using `ts-ignore` and not `ts-expect-error` here as TypeScript will fail on this line without `ts-ignore` but with `ts-expect-error`
							// This error was introduced after upgrading to TypeScript 5
							noOptionsMessage={null}
							onChange={(received) => {
								// @ts-ignore - Argument of type 'Value' is not assignable to parameter of type 'OptionData | undefined'
								// Using `ts-ignore` and not `ts-expect-error` here as TypeScript will fail on this line without `ts-ignore` but with `ts-expect-error`
								// This error was introduced after upgrading to TypeScript 5
								onPrincipalChange(received);
							}}
							value={stagedPrincipal || []}
							filterSearchResults={(value) =>
								value?.extra?.permissionType !== SitePermissionType.EXTERNAL
							}
						/>
					</Box>
					<Box xcss={principlePickerFineTextStyle}>
						{intl.formatMessage(i18nBulk.userPickerSubtext)}
					</Box>

					<ActionSelectionRadioButtons
						actionSelection={actionSelection}
						onRadioChange={onActionRadioChange}
					/>

					<SpaceSelectionRadioButtons
						spaceSelection={spaceSelection}
						onRadioChange={onSpaceTypeRadioChange}
					/>

					<RoleSelectionSection onRoleChange={onRoleChange} roleAssignment={roleAssignment} />

					<Button
						appearance="primary"
						onClick={() => openDialog()}
						isDisabled={stagedPrincipal === undefined || roleAssignment === null}
					>
						{intl.formatMessage(
							isRemoval ? i18nBulk.applyButtonTextRemove : i18nBulk.applyButtonTextAdd,
						)}
					</Button>

					<Inline xcss={requirementTextStyle}>
						{intl.formatMessage(i18nBulk.requirementMessage, {
							asterisk: (chunks: React.ReactNode[]) => <Box xcss={asteriskStyle}>{chunks}</Box>,
						})}
					</Inline>
				</Box>
				{showDialog && (
					<BulkAccessModal
						principalName={stagedPrincipal?.name || ''}
						isRemoval={isRemoval}
						spaceSelection={spaceSelection}
						isLoading={false}
						onConfirm={onDialogConfirm}
						onCancel={closeDialog}
					/>
				)}
			</>
		);
	}),
);
