import { defineMessages } from 'react-intl-next';

import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';

const messagesBase = defineMessages({
	whiteboardManifestDescription: {
		id: 'whiteboard-extension.description',
		defaultMessage: 'Create an empty whiteboard',
		description: 'Description for whiteboard editor extension.',
	},
	whiteboardManifestTitle: {
		id: 'whiteboard-extension.title',
		defaultMessage: 'Whiteboard extension',
		description: 'Title for whiteboard editor extension.',
	},
	createWhiteboardTitle: {
		id: 'whiteboard-extension.create-whiteboard.title',
		defaultMessage: 'Create whiteboard',
		description: 'Title for create whiteboard quick insert action.',
	},
	createWhiteboardRetrospectiveTitle: {
		id: 'whiteboard-extension.create-retrospective.title',
		defaultMessage: 'Create retrospective',
		description: 'Title for create retrospective quick insert action.',
	},
	createWhiteboardDiagramTitle: {
		id: 'whiteboard-extension.create-diagram.title',
		defaultMessage: 'Create diagram',
		description: 'Title for create diagram quick insert action.',
	},
	createWhiteboardFlowchartTitle: {
		id: 'whiteboard-extension.create-flowchart.title',
		defaultMessage: 'Create flowchart',
		description: 'Title for create flowchart quick insert action.',
	},
	createWhiteboardBrainstormingTitle: {
		id: 'whiteboard-extension.create-brainstorming.title',
		defaultMessage: 'Create brainstorming',
		description: 'Title for create brainstorm quick insert action.',
	},
	createWhiteboardRoadmapTitle: {
		id: 'whiteboard-extension.create-roadmap.title',
		defaultMessage: 'Create roadmap',
		description: 'Title for create roadmap quick insert action.',
	},
	createWhiteboardDescription: {
		id: 'whiteboard-extension.create-whiteboard.description',
		defaultMessage: 'Create a blank whiteboard',
		description: 'Description for create whiteboard quick insert action.',
	},
	createWhiteboardRetrospectiveDescription: {
		id: 'whiteboard-extension.create-retrospective.description',
		defaultMessage: 'Create a whiteboard using the simple retrospective template',
		description:
			'Description for create whiteboard quick insert action, which creates a whiteboard with the simple retrospective template applied.',
	},
	createWhiteboardDiagramDescription: {
		id: 'whiteboard-extension.create-diagram.description',
		defaultMessage: 'Create a whiteboard using the flowchart template',
		description:
			'Description for create whiteboard quick insert action, which creates a whiteboard with the flowchart template applied.',
	},
	createWhiteboardFlowchartDescription: {
		id: 'whiteboard-extension.create-flowchart.description',
		defaultMessage: 'Create a whiteboard using the flowchart template',
		description:
			'Description for create whiteboard quick insert action, which creates a whiteboard with the flowchart template applied.',
	},
	createWhiteboardBrainstormingDescription: {
		id: 'whiteboard-extension.create-brainstorming.description',
		defaultMessage: 'Create a whiteboard using the brainwriting template',
		description:
			'Description for create whiteboard quick insert action, which creates a whiteboard with the flowchart template applied.',
	},
	createWhiteboardRoadmapDescription: {
		id: 'whiteboard-extension.create-roadmap.description',
		defaultMessage: 'Create a whiteboard using the product roadmap template',
		description:
			'Description for create whiteboard quick insert action, which creates a whiteboard with the product roadmap template applied.',
	},
	createWhiteboardIconAltText: {
		id: 'whiteboard-extension.create-whiteboard.icon-alt-text',
		defaultMessage: 'Create whiteboard icon',
		description: 'Alt text for create whiteboard quick insert action icon.',
	},
	successFlagTitle: {
		id: 'whiteboard-extension.success-flag.title',
		defaultMessage: 'Whiteboard added to content tree',
		description: 'Title for success flag when whiteboard is created inline.',
	},
	successFlagAction: {
		id: 'whiteboard-extension.success-flag.action',
		defaultMessage: 'Go to whiteboard',
		description: 'Action for success flag when whiteboard is created inline.',
	},
	successFlagDescription: {
		id: 'whiteboard-extension.success-flag.description',
		defaultMessage:
			'The source whiteboard has been created in the tree. Go to the whiteboard to move its location or change its permissions.',
		description: 'Description for success flag when whiteboard is created inline.',
	},
	whiteboardDefaultErrorFlagTitle: {
		id: 'whiteboard-extension.error-flag.title',
		defaultMessage: 'We’re unable to create right now',
		description: 'Title for default flag when whiteboard is created inline.',
	},
	whiteboardDefaultErrorFlagDescription: {
		id: 'whiteboard-extension.error-flag.description',
		defaultMessage:
			'Refresh or wait a few minutes and try again. If this keeps happening, contact support.',
		description: 'Description for success flag when whiteboard is created inline.',
	},
	whiteboardParentPageMovedErrorTitle: {
		id: 'whiteboard-extension.error.parent-page-moved-error-title',
		defaultMessage: 'The parent has moved',
		description:
			'Title for error flag when the page is moved when trying to create a whiteboard inline.',
	},
	whiteboardParentPageMovedErrorDescription: {
		id: 'whiteboard-extension.error.parent-page-moved-error-description',
		defaultMessage: 'Refresh to create in the new location.',
		description:
			'Description for error flag when the page is moved when trying to create a whiteboard inline.',
	},
	whiteboardPermissionRemovedErrorTitle: {
		id: 'whiteboard-extension.error.permission-removed-error-title',
		defaultMessage: 'We’re unable to create this content',
		description:
			'Title for error flag when the user encounters a permissions issue while creating content or tries to create content in a nonexistent space',
	},
	whiteboardPermissionRemovedErrorDescription: {
		id: 'whiteboard-extension.error.permission-removed-error-description',
		defaultMessage:
			'You don’t have permission to create here or the space has been recently deleted.',
		description:
			'Description for error flag when the user encounters a permissions issue while creating content or tries to create content in a nonexistent space.',
	},
	whiteboardInvalidParentErrorTitle: {
		id: 'whiteboard-extension.error.invalid-parent-error-title',
		defaultMessage: 'We can’t create a whiteboard',
		description:
			'Title for error flag when the page is deleted or archived when trying to create a whiteboard inline.',
	},
	whiteboardInvalidParentErrorDescription: {
		id: 'whiteboard-extension.error.invalid-parent-error-description',
		defaultMessage:
			'This page was deleted or archived. You can create a whiteboard on a different page.',
		description:
			'Description for error flag when when the page is deleted or archived when trying to create a whiteboard inline.',
	},
});

const messagesLiveDocs = defineMessages({
	whiteboardInvalidParentErrorDescription: {
		id: 'whiteboard-extension.error.invalid-parent-error-description.livedocs',
		defaultMessage:
			'This item was deleted or archived. You can create a whiteboard on a different page.',
		description: 'Updated version of whiteboard-extension.error.invalid-parent-error-description',
	},
});

export const messages = mergeLiveDocI18n(messagesBase, messagesLiveDocs);
