/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BulkRoleAssignmentSpaceType {
  COLLABORATION = "COLLABORATION",
  GLOBAL = "GLOBAL",
  KNOWLEDGE_BASE = "KNOWLEDGE_BASE",
  PERSONAL = "PERSONAL",
}

export enum RoleAssignmentPrincipalType {
  ACCESS_CLASS = "ACCESS_CLASS",
  GROUP = "GROUP",
  TEAM = "TEAM",
  USER = "USER",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkSetRoleAssignmentToSpaces
// ====================================================

export interface BulkSetRoleAssignmentToSpaces_bulkSetRoleAssignmentToSpaces_errors {
  message: string | null;
}

export interface BulkSetRoleAssignmentToSpaces_bulkSetRoleAssignmentToSpaces {
  taskId: string | null;
  success: boolean;
  errors: BulkSetRoleAssignmentToSpaces_bulkSetRoleAssignmentToSpaces_errors[] | null;
}

export interface BulkSetRoleAssignmentToSpaces {
  bulkSetRoleAssignmentToSpaces: BulkSetRoleAssignmentToSpaces_bulkSetRoleAssignmentToSpaces;
}

export interface BulkSetRoleAssignmentToSpacesVariables {
  principalId: string;
  principalType: RoleAssignmentPrincipalType;
  roleId: string;
  spaceTypes: (BulkRoleAssignmentSpaceType | null)[];
}
